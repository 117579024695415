import axios from "axios";
import CONFIG from "../config";

export const addCategory = async (bodyObj) => {
  try {
    let response = await axios.post(
      CONFIG.BASEURL + "/admin/categories/add",
      bodyObj
    );
    return response.data;
  } catch (err) { }
};

export const updateCat = async (bodyObj) => {
  try {
    let response = await axios.post(
      CONFIG.BASEURL + "/admin/categories/add",
      bodyObj
    );
    return response.data;
  } catch (err) { }
};

export const fetchCategories = async (bodyObj) => {
  try {
    let response = await axios.post(
      CONFIG.BASEURL + "/admin/categories/listChildCategories",
      bodyObj
    );
    if (response.data.success) {
      return response.data.categories;
    }
    else {
      throw response.data;
    }
  } catch (err) {
    throw err;
  }
};


export const getAllLeafCategories = async () => {
  try {
    let response = await axios.get(
      CONFIG.BASEURL + "/admin/categories/listAllLeafProductCategories"
    );
    if (response.data.success) {
      return response.data.categories;
    }
    else {
      throw response.data;
    }
  }
  catch (err) {
    throw err;
  }

}