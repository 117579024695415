import * as React from "react";
import { Modal, Button, Accordion, Nav, Col, Tab, Row } from "react-bootstrap";
import ProductDisplay from "./product-display";
import ProductAttributes from "./product-attributes"
import Stock from "./stock";
import "mdi-icons/css/materialdesignicons.min.css";

export default class AdvancedInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show: false, categorySelected: [] };
    }
    componentDidMount() { }

    render() {
        return (
            <div className="card card-rounded">
                <div className="card-body basic-info">
                    <div className="row">
                        <div className="col-lg-12">
                            <Accordion className="panel-group" id="accordion">
                                <Accordion.Item eventKey="0" className="panel panel-default">
                                    <div className="panel-heading" role="tab" id="headingOne">
                                        <Accordion.Header className="panel-title" >
                                            <span className="icon"><i className="plus-minus mdi mdi-plus"></i></span>&nbsp;Advanced info
                                        </Accordion.Header>
                                    </div>
                                    <Accordion.Body>
                                        <div className="panel-body">
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                                <div className="ecomchain-tab-container row">
                                                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 ecomchain-tab-menu">
                                                        <div className="list-group">
                                                            <Nav variant="pills" className="flex-column">
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="first" className="list-group-item" title="Product display">  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.33333 3.33333C1.33333 2.59695 1.93029 2 2.66667 2H13.3333C14.0697 2 14.6667 2.59695 14.6667 3.33333V9.33333C14.6667 10.0697 14.0697 10.6667 13.3333 10.6667H12.6667V13.3333C12.6667 13.7015 12.3682 14 12 14H10C9.63181 14 9.33333 13.7015 9.33333 13.3333V10.6667H6.66667V13.3333C6.66667 13.7015 6.36819 14 6 14H4C3.63181 14 3.33333 13.7015 3.33333 13.3333V10.6667H2.66667C1.93029 10.6667 1.33333 10.0697 1.33333 9.33333V3.33333ZM10 9.33333H12H13.3333V8V4.66667V3.33333H12H4H2.66667V4.66667V8V9.33333H4H6H10ZM10.6667 10.6667V12.6667H11.3333V10.6667H10.6667ZM4.66667 10.6667H5.33333V12.6667H4.66667V10.6667Z" fill="" />
                                                                    </svg>
                                                                        Product display</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="second" className="list-group-item" title="Product display"> <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.36366 9.95136L1.82239 12.6437C1.98113 13.5754 2.73295 14.2838 3.65823 14.3737L6.57832 14.6572C7.19075 14.7166 7.79729 14.4948 8.2323 14.0522L14.0627 8.12094C14.868 7.30173 14.868 5.97352 14.0627 5.15431L10.9107 1.94774C10.1054 1.12853 8.79981 1.12853 7.99453 1.94774L1.93732 8.10978C1.46309 8.59221 1.24912 9.27912 1.36366 9.95136ZM3.78883 12.9815C3.48041 12.9516 3.2298 12.7154 3.17688 12.4049L2.71815 9.71252C2.67997 9.48844 2.7513 9.25947 2.90937 9.09866L8.96658 2.93662C9.23501 2.66355 9.67021 2.66355 9.93864 2.93662L13.0907 6.14319C13.3591 6.41626 13.3591 6.85899 13.0907 7.13206L7.26025 13.0634C7.11525 13.2109 6.91307 13.2848 6.70892 13.265L3.78883 12.9815ZM5.84404 11.3575C6.12875 11.0679 6.12875 10.5983 5.84404 10.3086C5.55934 10.019 5.09773 10.019 4.81302 10.3086C4.52832 10.5983 4.52832 11.0679 4.81302 11.3575C5.09773 11.6471 5.55934 11.6471 5.84404 11.3575Z" fill="" />
                                                                    </svg>
                                                                        Attributes</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="third" className="list-group-item" title="Product display"> <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M8 1.33333C3.89473 1.33333 2.05355 3.87016 1.59425 4.64298L2.74044 5.32417L1.59425 4.64298C1.3987 4.97202 1.33333 5.31574 1.33333 5.61058V12.6667C1.33333 13.7712 2.22876 14.6667 3.33333 14.6667H4H5.33333H10.6667H12H12.6667C13.7712 14.6667 14.6667 13.7712 14.6667 12.6667V5.61058C14.6667 5.31574 14.6013 4.97202 14.4057 4.64298C13.9464 3.87016 12.1053 1.33333 8 1.33333ZM2.74044 5.32417C3.07029 4.76917 4.54766 2.66667 8 2.66667C11.4523 2.66667 12.9297 4.76917 13.2596 5.32417C13.3111 5.41095 13.3333 5.50963 13.3333 5.61058V12.6667C13.3333 13.0349 13.0349 13.3333 12.6667 13.3333H12V6.66667C12 5.93029 11.403 5.33333 10.6667 5.33333H5.33333C4.59695 5.33333 4 5.93029 4 6.66667V13.3333H3.33333C2.96514 13.3333 2.66667 13.0349 2.66667 12.6667V5.61058C2.66667 5.50963 2.68887 5.41096 2.74044 5.32417ZM10.6667 10.6667V9.33333H5.33333V10.6667H10.6667ZM10.6667 12H5.33333V13.3333H10.6667V12ZM10.6667 6.66667V8H5.33333V6.66667H10.6667Z" fill="" />
                                                                    </svg>
                                                                        Stock </Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ecomchain-tab">

                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="first" className="ecomchain-tab-content in">
                                                                <ProductDisplay product={this.props.product}
                                                                    updateDetails={this.props.updateDetails} />
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="second" className="ecomchain-tab-content in">

                                                                <ProductAttributes attributes={this.props.attributes} product={this.props.product} updateDetails={this.props.updateDetails}/>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="third" className="ecomchain-tab-content in">
                                                                <Stock product={this.props.product} updateDetails={this.props.updateDetails}/>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </div>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}