import { LIST_ATTRIBUTES, ADD_ATTRIBUTE, ATTRIBUTE_ERROR,DELETE_ATTRIBUTE } from "./attributes.types";
import { fetchAttributes, saveAttribute,deleteAttributes } from "../../api/attributes";
import { beginApiCall, apiCallError, apiCallSuccess } from "../apiStatus/api.actions";

export const listAttributesSuccess = (attributes) => {
    return {
        type: LIST_ATTRIBUTES,
        payload: attributes,
    };
};

export const listAttributes = () => {
    return async (dispatch) => {
        dispatch(beginApiCall());
        try {
            let attributes = await fetchAttributes();
            dispatch(listAttributesSuccess(attributes));
        } catch (err) { }
    };
};

export const addAttributeSuccess = (result) => {
    return {
        type: ADD_ATTRIBUTE,
        payload: result,
    };
};

export const deleteAttributeSuccess = (result) => {
    return {
        type: DELETE_ATTRIBUTE,
        payload: result,
    };
};

export const attributeError = (result) => {
    return {
        type: ADD_ATTRIBUTE,
        payload: result,
    };
};

export const addAttribute = (bodyObj) => {
    return async (dispatch) => {
        dispatch(beginApiCall());
        try {
            let result = await saveAttribute(bodyObj);
            if (result) {
                dispatch(addAttributeSuccess(result));
                dispatch(apiCallSuccess(result))
                setTimeout(() => {
                    dispatch(apiCallSuccess({ message: "" }))
                }, 10000)
            }
        } catch (err) {
            dispatch(apiCallError(err))
            setTimeout(() => {
                dispatch(apiCallError({ message: "" }))
            }, 10000)
        }
    };
};

export const deleteAttribute = (bodyObj) => {
    return async (dispatch) => {
        dispatch(beginApiCall());
        try {
            let result = await deleteAttributes(bodyObj);
            if (result) {
                dispatch(deleteAttributeSuccess(result));
                dispatch(apiCallSuccess(result))
                setTimeout(() => {
                    dispatch(apiCallSuccess({ message: "" }))
                }, 10000)
            }
        } catch (err) {
            dispatch(apiCallError(err))
            setTimeout(() => {
                dispatch(apiCallError({ message: "" }))
            }, 10000)
        }
    };
}