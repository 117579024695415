import * as React from "react";
import { Modal, Button } from "react-bootstrap";

export default class ProductDisplay extends React.Component {
    constructor(props) {
      super(props);
      this.state = { show: false, categorySelected: [] };
    }
    componentDidMount() {}
  
    render() {
      return (
          <>
  <div className="row">
  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <label for="input">UPC barcode</label>
      <input type="text" className="form-control"  onChange={(e) =>
                    this.props.updateDetails("upc", e.target.value,"product_display")
                  }
                  value={this.props.product.product_display && this.props.product.product_display.upc ? this.props.product.product_display.upc : ""}
                  />
    </div>
  </div>
  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <label for="input">New product?</label>
      <select className="select-db form-control" onChange={(e) =>
                    this.props.updateDetails("newProduct", e.target.value=="true" ? true :  false,"product_display")
                  }
                  value={this.props.product.product_display && this.props.product.product_display.newProduct ? JSON.stringify(this.props.product.product_display.newProduct) : "false"}
                  >
        <option value={false}>No</option>
        <option value={true}>Yes</option>
      </select>
    </div>
  </div>
</div>
<div className="row">
  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <label for="input">Product tag</label>
      <input type="text" className="form-control" onChange={(e) =>
                    this.props.updateDetails("productTag", e.target.value,"product_display")
                  }
                  value={this.props.product.product_display && this.props.product.product_display.productTag ? this.props.product.product_display.productTag : ""}
                  />
    </div>
  </div>
  {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <label for="input" style={{whiteSpace: 'nowrap'}}>Minimum Order Quantity</label>
      <input type="text" className="form-control" placeholder="1"/>
    </div>
  </div> */}
</div>
<div className="row">
  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <div className="pure-checkbox">
        <input id="checkbox04" name="checkbox" type="checkbox" onChange={(e) =>
                    this.props.updateDetails("hideProduct", e.target.checked ? true :  false,"product_display")
                  }
                  checked={this.props.product.product_display && this.props.product.product_display.hideProduct ? this.props.product.product_display.hideProduct : false}
                  />
        <label for="checkbox04">Hide product</label>
      </div>
    </div>
  </div>
  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <div className="pure-checkbox">
        <input id="checkbox05" name="checkbox" type="checkbox" onChange={(e) =>
                    this.props.updateDetails("hideReviews", e.target.checked ? true :  false,"product_display")
                  }
                  checked={this.props.product.product_display && this.props.product.product_display.hideReviews ? this.props.product.product_display.hideReviews : false}
                  />
        <label for="checkbox05">Hide customer reviews</label>
      </div>
    </div>
  </div>
</div>

{/* <div className="row">
 <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <div className="pure-checkbox">
        <input id="checkbox6" name="checkbox" type="checkbox"/>
        <label for="checkbox6">Enable digital dressing</label>
      </div>
    </div>
  </div> 
  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <div className="pure-checkbox">
        <input id="checkbox7" name="checkbox" type="checkbox"/>
        <label for="checkbox7">Cresting</label>
      </div>
    </div>
  </div>
</div> */}
<div className="row">
  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <div className="pure-checkbox">
        <input id="checkbox8" name="checkbox" type="checkbox" onChange={(e) =>
                    this.props.updateDetails("hideOutOfStock", e.target.checked ? true :  false,"product_display")
                  }
                  checked={this.props.product.product_display && this.props.product.product_display.hideOutOfStock ? this.props.product.product_display.hideOutOfStock : false}

                  />
        <label for="checkbox8">Hide when out of stock</label>
      </div>
    </div>
  </div>
  
  {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12"/>
    <div className="form-group">
      <div className="pure-checkbox">
        <input id="checkbox9" name="checkbox" type="checkbox"/>
        <label for="checkbox9">Show stock count</label>
      </div>
    </div>*/}
  </div> 
{/* <div className="row">
  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <div className="pure-checkbox">
        <input id="checkbox10" name="checkbox" type="checkbox"/>
        <label for="checkbox10">Product for display only</label>
      </div>
    </div>
  </div>
  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <div className="pure-checkbox">
        <input id="checkbox11" name="checkbox" type="checkbox"/>
        <label for="checkbox11">Hide price</label>
      </div>
    </div>
  </div>
</div> */}
{/* <div className="row">
  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <label for="input">Display begin date</label>
      <div className="date-input-icon"></div>
      <input type="text" className="form-control date" id="datepicker2" placeholder="dd/mm/yyyy"/>
    </div>
  </div>
  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div className="form-group">
      <label for="input">Display end date</label>
      <div className="date-input-icon"></div>
      <input type="text" className="form-control date" id="datepicker3" placeholder="dd/mm/yyyy"/>
    </div>
  </div>
</div> */}
</>
    )
}
}