import * as React from "react";


Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
};

export default class ImageManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = { images: [] };
        this.onSelectFile = this.onSelectFile.bind(this)

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.product != prevProps.product && this.props.product.images && this.props.product.images.length > 0) {
            const imageList = [...this.props.product.images]
            imageList.shift()
            this.setState({
                images: imageList
            })
        }
    }

    async onSelectFile(event, position = null) {
        const file = event.target.files[0];
        const { images } = this.state
        const convertedFile = await this.convertToBase64(file);

        let resultArray = [...images];
        if (position != null) {
            // resultArray.splice(position,0,convertedFile)
            resultArray[position] = convertedFile
        }
        else {
            resultArray = [...resultArray, convertedFile]
        }
        console.log(resultArray)
        this.setState({
            "images": resultArray
        })
        this.props.updateDetails("images", resultArray, '', "otherImages")
        // Request will be sent from here in the future
    }
    convertToBase64(file) {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            }
        })
    }


    render() {
        const { images } = this.state
        const { product } = this.props

        return (
            <div className="card set-height card-rounded">
                <div className="card-body">
                    <h2 className="header-title">Image Management</h2>
                    <div className="row">
                        <div className="col-lg-12">
                            <input
                                type="file"
                                id="input-file-max-fs"
                                className="dropify"
                                data-max-file-size="2M"
                                disabled={!product.images || product.images.length == 0 || product.images.length == 5}
                                onChange={(e) => { this.onSelectFile(e) }}
                            />
                        </div>
                    </div>
                    <div className="row" >
                        {(!product.images || product.images.length == 0) && <div className="col-lg-12"><span style={{ color: 'red' }}>Please upload main image
                        </span></div>}
                        {images && images.length > 0 && images.map((image, index) => (

                            <div className="col-lg-12 dropify-margin">
                                <input
                                    type="file"
                                    id="input-file-max-fs"
                                    className="dropify"
                                    data-max-file-size="2M"
                                    onChange={(e) => { this.onSelectFile(e, index) }}
                                />
                                <img src={image ? image : "/images/products/no-image-available.jpg"} />
                            </div>

                        ))}
                    </div>
                </div>
            </div>
        )
    }
}