import axios from "axios";
import CONFIG from "../config";

export const fetchAttributes = async () => {
    try {
        let response = await axios.get(
            CONFIG.BASEURL + "/admin/attributes"
        );
        if (response.data.success === true) {
            return response.data.attributes;
        }
        else {
            throw response.data;
        }
    } catch (err) {
        throw err;
    }
};

export const saveAttribute = async (bodyObj) => {
    try {
        let response;
        if (bodyObj._id) {
            response = await axios.put(
                CONFIG.BASEURL + "/admin/attributes/" + bodyObj._id, bodyObj
            );
        }
        else {
            response = await axios.post(
                CONFIG.BASEURL + "/admin/attributes", bodyObj
            );
        }

        if (response.data.success === true) {
            return response.data;
        }
        else {
            throw response.data;
        }
    } catch (err) {
        throw err;
    }
};

export const deleteAttributes = async (bodyObj) => {
    try {
        let response = await axios.post(
            CONFIG.BASEURL + "/admin/attributes/delete", bodyObj
        );
        if (response.data.success === true) {
            return response.data;
        }
        else {
            throw response.data;
        }
    } catch (err) { }
}