import { BEGIN_API_CALL, API_CALL_ERROR, API_CALL_SUCCESS } from "./api.types";

const INITIAL_STATE = {
    apiError: '',
    apiSuccess: ''
};

const apiReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BEGIN_API_CALL:
            return { ...state, apiError: '', apiSuccess:'' };
        case API_CALL_SUCCESS:
            return { ...state, apiSuccess: action.payload.message };
        case API_CALL_ERROR:
            return { ...state, apiError: action.payload.message };
        default:
            return state;
    }
}

export default apiReducer;