import * as React from "react";

export default class ProductTools extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row">
        <div className="products-tools row">
          <div className="products-grid-list">
            <div className="btn-group" role="group" aria-label="...">
              <button
                type="button"
                className="btn btn-default active"
                title="Grid view"
                onClick={(e) => this.props.switchView("grid")}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.75 2.25C2.92157 2.25 2.25 2.92157 2.25 3.75V4.5V6.75C2.25 7.57843 2.92157 8.25 3.75 8.25H6.75C7.57843 8.25 8.25 7.57843 8.25 6.75V3.75C8.25 2.92157 7.57843 2.25 6.75 2.25H3.75ZM3.75 3.75H6.75V6.75H3.75V4.5V3.75ZM11.25 2.25C10.4216 2.25 9.75 2.92157 9.75 3.75V4.5V6.75C9.75 7.57843 10.4216 8.25 11.25 8.25H14.25C15.0784 8.25 15.75 7.57843 15.75 6.75V3.75C15.75 2.92157 15.0784 2.25 14.25 2.25H11.25ZM11.25 3.75H14.25V6.75H11.25V4.5V3.75ZM9.75 11.25C9.75 10.4216 10.4216 9.75 11.25 9.75H14.25C15.0784 9.75 15.75 10.4216 15.75 11.25V14.25C15.75 15.0784 15.0784 15.75 14.25 15.75H11.25C10.4216 15.75 9.75 15.0784 9.75 14.25V12V11.25ZM14.25 11.25H11.25V12V14.25H14.25V11.25ZM3.75 9.75C2.92157 9.75 2.25 10.4216 2.25 11.25V12V14.25C2.25 15.0784 2.92157 15.75 3.75 15.75H6.75C7.57843 15.75 8.25 15.0784 8.25 14.25V11.25C8.25 10.4216 7.57843 9.75 6.75 9.75H3.75ZM3.75 11.25H6.75V14.25H3.75V12V11.25Z"
                    fill="#0A6CFF"
                  />
                </svg>
                <span className="xs-landscape-hide">Grid view</span>
              </button>
              <button
                type="button"
                className="btn btn-default"
                title="List view"
                onClick={(e) => this.props.switchView("list")}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.875 16.5L4.125 16.5C3.08946 16.5 2.25 15.6605 2.25 14.625L2.25 13.875C2.25 12.8395 3.08947 12 4.125 12L13.875 12C14.9105 12 15.75 12.8395 15.75 13.875L15.75 14.625C15.75 15.6605 14.9105 16.5 13.875 16.5ZM13.875 15C14.0821 15 14.25 14.8321 14.25 14.625L14.25 13.875C14.25 13.6679 14.0821 13.5 13.875 13.5L4.125 13.5C3.91789 13.5 3.75 13.6679 3.75 13.875L3.75 14.625C3.75 14.8321 3.91789 15 4.125 15L13.875 15ZM13.875 11.25L4.125 11.25C3.08946 11.25 2.25 10.4105 2.25 9.375L2.25 8.625C2.25 7.58947 3.08947 6.75 4.125 6.75L13.875 6.75C14.9105 6.75 15.75 7.58947 15.75 8.625L15.75 9.375C15.75 10.4105 14.9105 11.25 13.875 11.25ZM13.875 9.75C14.0821 9.75 14.25 9.58211 14.25 9.375L14.25 8.625C14.25 8.41789 14.0821 8.25 13.875 8.25L4.125 8.25C3.91789 8.25 3.75 8.41789 3.75 8.625L3.75 9.375C3.75 9.58211 3.91789 9.75 4.125 9.75L13.875 9.75ZM4.125 6L13.875 6C14.9105 6 15.75 5.16053 15.75 4.125L15.75 3.375C15.75 2.33947 14.9105 1.5 13.875 1.5L4.125 1.5C3.08947 1.5 2.25 2.33947 2.25 3.375L2.25 4.125C2.25 5.16053 3.08946 6 4.125 6ZM14.25 4.125C14.25 4.33211 14.0821 4.5 13.875 4.5L4.125 4.5C3.91789 4.5 3.75 4.33211 3.75 4.125L3.75 3.375C3.75 3.16789 3.91789 3 4.125 3L13.875 3C14.0821 3 14.25 3.16789 14.25 3.375L14.25 4.125Z"
                    fill=""
                  />
                </svg>
                <span className="xs-landscape-hide">List view</span>
              </button>
            </div>
          </div>
          <div className="products-sortby products-sortby-xs">
            <div className="dropdown">
              {" "}
              <span>Filter By</span>
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {" "}
                Name A-Z <span className="mdi mdi-chevron-down mdi-xs"></span>{" "}
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li>
                  <a href="#">Name Z-A</a>
                </li>
                <li>
                  <a href="#">createdAt Asc</a>
                </li>
                <li>
                  <a href="#">createdAt Desc</a>
                </li>
              </ul>
            </div>
            <div className="dropdown">
              {" "}
              <span>Show Per Page</span>
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {" "}
                12 <span className="mdi mdi-chevron-down mdi-xs"></span>{" "}
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li>
                  <a href="#">16</a>
                </li>
                <li>
                  <a href="#">20</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
