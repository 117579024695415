import * as React from "react";
import Titlebox from "../common/Titlebox";
import { Modal, Button, Form, Table, TableR } from 'react-bootstrap';
import { getOrder } from '../../api/orders'
import './OrderDetails.css'
import OrderList from "./OrderList";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom"
import noImage from "./no-image-available.jpg"

class OrderDetails extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            order: {}
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {

            this.findOrder(this.props.match.params.id);
        }
    }

    async findOrder() {
        try {
            let orders = await getOrder(this.props.match.params.id);
            this.setState({ order: orders[0] })
            console.log(this.state.order.shippingAddress)
        }
        catch (err) {

        }
    }

    render() {
        return (
            <div id="db-page-wrapper">
                <div className="page-content-tab">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <Titlebox title="Order Details" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td className="column-left">
                                                        <strong>Order Number</strong>
                                                    </td>
                                                    <td className="column-right">
                                                        {this.state.order.orderNumber}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="column-left">
                                                        <strong>Order Date</strong>
                                                    </td>
                                                    <td className="column-right">
                                                        {new Date(this.state.order.createdAt).toDateString()}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="column-left">
                                                        <strong>Order Status</strong>
                                                    </td>
                                                    <td className="column-right">
                                                        {this.state.order.orderStatus}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="column-left">
                                                        <strong>Order Email</strong>
                                                    </td>
                                                    <td className="column-right">
                                                        {this.state.order.siteUser ? this.state.order.siteUser.email : this.state.order.emailAddress}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td className="column-left">
                                                        <strong>Shipping Address</strong>
                                                    </td>
                                                    {this.state.order && this.state.order.shippingAddress && <td className="column-right">

                                                        {this.state.order.shippingAddress.firstname} {this.state.order.shippingAddress.lastname}
                                                        <br />
                                                        {this.state.order.shippingAddress.address1}
                                                        <br />
                                                        {this.state.order.shippingAddress.address2}
                                                        <br />
                                                        {this.state.order.shippingAddress.city},  {this.state.order.shippingAddress.state}
                                                        <br />
                                                        {this.state.order.shippingAddress.country} - {this.state.order.shippingAddress.pincode}
                                                        <br />
                                                        {this.state.order.shippingAddress.phone}
                                                    </td>}
                                                </tr>
                                                <tr>
                                                    <td className="column-left">
                                                        <strong>Billing Address</strong>
                                                    </td>
                                                    {this.state.order && this.state.order.billingAddress && <td className="column-right">

                                                        {this.state.order.billingAddress.firstname} {this.state.order.shippingAddress.lastname}
                                                        <br />
                                                        {this.state.order.billingAddress.address1}
                                                        <br />
                                                        {this.state.order.billingAddress.address2}
                                                        <br />
                                                        {this.state.order.billingAddress.city},  {this.state.order.billingAddress.state}
                                                        <br />
                                                        {this.state.order.billingAddress.country} - {this.state.order.billingAddress.pincode}
                                                        <br />
                                                        {this.state.order.billingAddress.phone}
                                                    </td>}
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                    <table className="table table-responsive table-bordered basket-desktop hidden-xs">
                                    <thead>
                                        <tr >
                                            <th></th>
                                            <th>Product</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Total</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {
                                            this.state.order && this.state.order.items && this.state.order.items.map(item => (

                                                <tr >
                                                    <td className="cart_product_img align-items-center">
                                                        <a href="#"><img src={item.itemId.images && item.itemId.images.length > 0 ? item.itemId.images[0] : noImage} alt="Product" /></a>
                                                    </td>
                                                    <td>
                                                        <h5>{item.itemId.name}</h5>

                                                        <span className="sku-style">SKU Name : <b>{item.skuId.skuName}</b></span>
                                                        <span className="sku-style">SKU Code : <b>{item.skuId.skuCode}</b></span>

                                                    </td>
                                                    <td className="price"><span>&#8377; {item.unitPrice}</span></td>
                                                    <td className="qty">
                                                    <div class="inner">
                                                  
                                                    <span class="quantity-desktop">
                                                        <span class="value">
                                                            1
                                                        </span>
                                                    </span>
                                                  
                                                </div>
                                                    </td>
                                                    <td className="total_price"><span>&#8377; {item.totalPrice}</span></td>
                                                </tr>

                                            ))
                                        }
                                     
                                    </tbody>
                                </table>
                                <table  className="table table-responsive basket-desktop">
                                    <tbody>
                                    <tr>
                                    <td style={{textAlign:'right',width:'90%'}}>
                                    <span><strong>Subtotal</strong></span>
                                        </td>
                                            <td style={{textAlign:'right'}}>
                                         <span>&#8377; {this.state.order.subtotal}</span>
                                           </td>
                                        </tr>
                                        <tr>
                                    <td style={{textAlign:'right',width:'90%'}}>
                                    <span><strong>Shipping</strong></span>
                                        </td>
                                            <td style={{textAlign:'right'}}>
                                         <span>Free</span>
                                           </td>
                                        </tr>
                                        <tr>
                                    <td style={{textAlign:'right',width:'90%'}}>
                                    <span><strong>Total</strong></span>
                                        </td>
                                            <td style={{textAlign:'right'}}>
                                         <span>&#8377; {this.state.order.subtotal}</span>
                                           </td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer id="footer" className="text-left">
                        &copy; <script>document.write(new Date().getFullYear())</script>{" "}
                        eComchain Inc. All rights reserved.
                    </footer>
                </div>
            </div>
        )
    }
}

export default withRouter(OrderDetails)