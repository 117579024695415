import { BEGIN_API_CALL, API_CALL_ERROR, API_CALL_SUCCESS } from "./api.types";

export const beginApiCall = () => {
  return { type: BEGIN_API_CALL };
};

export const apiCallSuccess = (success) => {
  return { type: API_CALL_SUCCESS, payload: success };
};

export const apiCallError = (error) => {
  return { type: API_CALL_ERROR, payload: error };
};

