import * as React from "react";

export default class AttributeList extends React.Component {
  constructor(props){
    super(props)
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="product-list-view table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th className="text-center">Select </th>
                  <th className="text-center">
                    Attribute name
                    <i
                      className="ml-2 mdi mdi-alert-circle-outline"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Attribute name"
                    ></i>
                  </th>
                  <th className="text-center">
                    Values
                    <i
                      className="ml-2 mdi mdi-alert-circle-outline"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Values"
                    ></i>
                  </th>
                  <th className="text-center">
                    Action
                    <i
                      className="ml-2 mdi mdi-alert-circle-outline"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="Edit product details"
                    ></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.attributes &&
                  this.props.attributes.map((attribute, index) => {
                    return (
                      <tr>
                        <td className="text-center">
                          <div className="pure-checkbox text-center">
                            <input
                              id={"checkbox" + attribute._id}
                              name="checkbox"
                              type="checkbox"
                              onChange={e=>this.props.select(attribute._id,e.target.checked)}
                            />
                            <label
                              htmlFor={"checkbox" + attribute._id}
                              className="m-0"
                            ></label>
                          </div>
                        </td>
                        <td className="text-center">{attribute.name}</td>
                        <td className="text-center">{attribute.values.length}</td>
                        <td className="text-center">
                          <div className="action text-center">
                            <a title="Edit" onClick={e=>this.props.openModal(attribute)}>
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.7074 1.94158C12.2962 1.35281 13.2508 1.35281 13.8395 1.94158L16.0584 4.16047C16.6472 4.74924 16.6472 5.70382 16.0584 6.29259L6.85629 15.4947C6.64672 15.7043 6.38003 15.8475 6.08955 15.9063L3.30699 16.47C2.81165 16.5703 2.29898 16.4158 1.9416 16.0584C1.58423 15.701 1.4297 15.1884 1.53004 14.693L2.09371 11.9104C2.15255 11.62 2.2957 11.3533 2.50527 11.1437L11.7074 1.94158ZM3.33261 13.3882L3.42955 12.9097L5.09032 14.5704L4.61175 14.6674L3.00767 14.9923L3.33261 13.3882ZM6.43545 13.7834L4.21656 11.5646L10.6887 5.09241L12.9076 7.3113L6.43545 13.7834ZM13.9736 6.24524L14.9924 5.22653L13.9263 4.16047L13.8395 4.0737L12.7735 3.00764L11.7548 4.02635L13.9736 6.24524Z"
                                  fill="#0A6CFF"
                                />
                              </svg>
                            </a>{" "}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
