import * as React from "react";
import { Modal, Button } from "react-bootstrap";

export default class BasicInfo extends React.Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.state = { show: false, categorySelected: [], image: "" };
    this.onSelectFile = this.onSelectFile.bind(this)
  }
  componentDidMount() {


   }

   componentDidUpdate(prevProps, prevState) {
    if(this.props.product !== prevProps.product && this.props.product.images && this.props.product.images.length>0) {
      console.log(this.props.product.images[0])
      this.setState({
        image:this.props.product.images[0]
      })
    } 
   }

  openPopup() {
    this.setState({ show: true });
  }

  closePopup() {
    this.setState({ show: false });
  }
  selectCategory(e, catId) {
    let catSelected = this.state.categorySelected;
    if (e.target.checked) {
      catSelected.push(catId);
      this.setState({ categorySelected: catSelected });
    } else {
      catSelected.splice(catSelected.indexOf(catId), 1);
      this.setState({ categorySelected: catSelected });
    }
  }

  async onSelectFile(event) {
    const file = event.target.files[0];
    const convertedFile = await this.convertToBase64(file);
    console.log(convertedFile)
    this.setState({
      "image": convertedFile
    })
    this.props.updateDetails("images", convertedFile,'',"mainimage")
    // Request will be sent from here in the future
  }
  convertToBase64(file) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      }
    })
  }


  render() {
    return (
      <div className="card set-height card-rounded">
        <div className="card-body basic-info">
          <h2 className="header-title">Basic info</h2>
          <div className="row">
            <div className="col-lg-12 dropify-margin">
              <input
                type="file"
                id="input-file-max-fs"
                className="dropify"
                data-max-file-size="2M"
                onChange={this.onSelectFile}
              />
              <img src={this.state.image?this.state.image : "/images/products/no-image-available.jpg"} alt={this.props.product.name ? this.props.product.name : "Product Image"}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="form-group">
                <label htmlFor="input">
                  Product Name<span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    this.props.updateDetails("name", e.target.value)
                  }
                  value={this.props.product.name ? this.props.product.name : ''}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <div className="form-group">
                <label htmlFor="input">
                  Product Code<span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    this.props.updateDetails("code", e.target.value)
                  }
                  value={this.props.product.code ? this.props.product.code : ''}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <div className="form-group">
                <label htmlFor="input">
                  Retail Price<span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    this.props.updateDetails("listPrice", e.target.value)
                  }
                  value={this.props.product.listPrice ? this.props.product.listPrice : ''}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-3 col-xs-12">
              <div className="form-group">
                <label htmlFor="input">
                  Unit of measurement<span className="mandatory">*</span>
                </label>
                <select
                  className="select-db form-control"
                  onChange={(e) =>
                    this.props.updateDetails("uom", e.target.value)
                  }
                  value={this.props.product.uom ? this.props.product.uom : ''}

                >
                  <option value="">Select an Option</option>
                  <option value="Each">Each</option>
                  <option value="Kilogram">Kilogram</option>
                  <option value="Litre">Litre</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="d-block">Categories:</label>
                <button
                  type="button"
                  className="btn btn-secondary mr-sm-0 mr-md-4 mr-lg-4 mb-4 mb-sm-0 mb-md-0 mb-lg-0 btn-block-xs"
                  onClick={(e) => this.openPopup()}
                  title="Select Categories"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4 14.6667L12 14.6667C13.1046 14.6667 14 13.7712 14 12.6667V8L14 5.58626C14 5.0974 13.821 4.62548 13.4967 4.25964L12.3979 3.02L11.5428 2.02774C11.1629 1.58685 10.6098 1.33333 10.0278 1.33333H8H4C2.89543 1.33333 2 2.22876 2 3.33333V12.6667C2 13.7712 2.89543 14.6667 4 14.6667ZM10.5328 2.89813L11.3939 3.89744L12.4989 5.14405C12.607 5.266 12.6667 5.42331 12.6667 5.58626V8L12.6667 12.6667C12.6667 13.0349 12.3682 13.3333 12 13.3333L4 13.3333C3.63181 13.3333 3.33333 13.0349 3.33333 12.6667L3.33333 3.33333C3.33333 2.96514 3.63181 2.66667 4 2.66667L8 2.66667L10.0278 2.66667C10.2218 2.66667 10.4062 2.75117 10.5328 2.89813ZM10.1847 7.75301C10.4164 7.46692 10.3724 7.04712 10.0863 6.81534C9.80026 6.58356 9.38045 6.62758 9.14867 6.91366L7.48523 8.96682L6.8339 8.22635C6.59072 7.9499 6.16948 7.92292 5.89302 8.1661C5.61656 8.40928 5.58959 8.83053 5.83277 9.10698L7.00561 10.4403C7.13529 10.5877 7.32337 10.6705 7.51968 10.6665C7.71599 10.6626 7.90056 10.5722 8.02417 10.4197L10.1847 7.75301Z"
                      fill=""
                    />
                  </svg>
                  Select Categories{" "}
                </button>
              </div>

              <Modal
                show={this.state.show}
                animation={false}
                onHide={(e) => this.closePopup()}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Select category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-lg-12">
                      {this.props.categories &&
                        this.props.categories.map((category, index) => {
                          return (
                            <div className="pure-checkbox" key={index}>
                              <input
                                id={"checkbox" + category._id}
                                name={"checkbox" + category._id}
                                type="checkbox"
                                onChange={(e) =>
                                  this.selectCategory(e, category._id)
                                }
                                defaultChecked={
                                  this.props.selectedCategories.indexOf(
                                    category._id
                                  ) > -1
                                }
                              />
                              <label htmlFor={"checkbox" + category._id}>
                                {category.name}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={(e) => this.closePopup()}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      this.props.updateDetails(
                        "categories",
                        this.state.categorySelected
                      );
                      this.closePopup();
                    }}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="input">Description</label>
                <textarea
                  className="form-control"
                  onChange={(e) =>
                    this.props.updateDetails("description", e.target.value)
                  }
                  value={this.props.product.description ? this.props.product.description : ''}

                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
