
import * as React from "react";
import { Modal, Button, ThemeProvider } from "react-bootstrap";
import { ObjectId } from "bson";

export default class ProductAttributes extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showAttributes: false, attributesSelected: [], skus: props.product.skus || [], newSKU: { attributes: [] } };
    }
    closePopup() {
        this.setState({ showAttributes: false });
    }
    selectAttribute(e, attribute) {
        if (e.target.checked) {
            this.setState({
                attributesSelected: [...this.state.attributesSelected, attribute]
            })
        }
        else {
            let index = this.state.attributesSelected.findIndex(attrsel => attrsel._id == attribute._id)
            let abc = [...this.state.attributesSelected];
            abc.splice(index, 1);
            this.setState({
                attributesSelected: abc
            })

            console.log(abc)
        }
    }

    createSKUs() {

        let sku = { ...this.state.newSKU };
        this.setState({ skus: [...this.state.skus, sku], newSKU: {} }, () => {
            this.props.updateDetails('skus', this.state.skus);
        });

    }

    updateSKUDetails(key, value, subkey = '') {
        let sku = this.state.newSKU;
        if (!sku._id) {
            sku._id = new ObjectId()
        }
        if (subkey == '') {
            if (key == "attributes") {
                if(sku[key]) {
                sku[key] = [...sku[key], value]
                }
                else{
                    sku[key] = [ value]
                }
            }
            else {
                sku[key] = value;
            }
        }
        else {
            sku[subkey][key] = value
        }
        this.setState({ newSKU: sku });
    }

    componentDidMount() { }

    componentDidUpdate(prevProps) {
        if (prevProps.product && prevProps.product != this.props.product) {
            this.setState({ attributesSelected: this.props.product.attributes, skus: this.props.product.skus });
        }
    }

    render() {
        const { product } = this.props;
        return (
            <>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="alert alert-info alert-dismissible">
                            <div className="description info">
                                <p><span>Attributes such as size and color can be setup for products and a specific product SKU can be created for each <a href="#">Click here to setup attributes to use with products</a> If you have already set the attributes you need, please continue below.</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
                        <button type="button" className="btn btn-primary" onClick={e => this.setState({ showAttributes: true })}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M4 14.6667L12 14.6667C13.1046 14.6667 14 13.7712 14 12.6667V8L14 5.58626C14 5.0974 13.821 4.62548 13.4967 4.25964L12.3979 3.02L11.5428 2.02774C11.1629 1.58685 10.6098 1.33333 10.0278 1.33333H8H4C2.89543 1.33333 2 2.22876 2 3.33333V12.6667C2 13.7712 2.89543 14.6667 4 14.6667ZM10.5328 2.89813L11.3939 3.89744L12.4989 5.14405C12.607 5.266 12.6667 5.42331 12.6667 5.58626V8L12.6667 12.6667C12.6667 13.0349 12.3682 13.3333 12 13.3333L4 13.3333C3.63181 13.3333 3.33333 13.0349 3.33333 12.6667L3.33333 3.33333C3.33333 2.96514 3.63181 2.66667 4 2.66667L8 2.66667L10.0278 2.66667C10.2218 2.66667 10.4062 2.75117 10.5328 2.89813ZM10.1847 7.75301C10.4164 7.46692 10.3724 7.04712 10.0863 6.81534C9.80026 6.58356 9.38045 6.62758 9.14867 6.91366L7.48523 8.96682L6.8339 8.22635C6.59072 7.9499 6.16948 7.92292 5.89302 8.1661C5.61656 8.40928 5.58959 8.83053 5.83277 9.10698L7.00561 10.4403C7.13529 10.5877 7.32337 10.6705 7.51968 10.6665C7.71599 10.6626 7.90056 10.5722 8.02417 10.4197L10.1847 7.75301Z" fill="white"></path>
                            </svg>
                            Select attributes</button>
                    </div>
                </div>
                {this.state.attributesSelected.length > 0 && <>
                    {this.state.attributesSelected.map((attributeSel, index) => (
                        <div className="row" key={"attrSel-" + index}>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <label for="input">{attributeSel.name}</label>
                                    <select className="select-db form-control" onChange={e => this.updateSKUDetails('attributes', { attr_type: attributeSel._id, attr_val: e.target.value })}>
                                        <option value=''>Select</option>

                                        {attributeSel && attributeSel.values && attributeSel.values.map(attrVal => (
                                            <option value={attrVal._id}>{attrVal.attr_name}</option>
                                        ))}

                                    </select>
                                    <small className="form-text-normal"><a href="#">Delete</a></small> </div>
                            </div>
                        </div>
                    ))}

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-group">
                                <label for="input">SKU name</label>
                                <input type="text" className="form-control" onChange={(e) =>
                                    this.updateSKUDetails("skuName", e.target.value)
                                }
                                    value={this.state.newSKU.skuName || ''} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-group">
                                <label for="input">SKU code</label>
                                <input type="text" className="form-control" onChange={(e) =>
                                    this.updateSKUDetails("skuCode", e.target.value)

                                }
                                    value={this.state.newSKU.skuCode || ''} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-group">
                                <label for="input">Price</label>
                                <input type="text" className="form-control" onChange={(e) =>
                                    this.updateSKUDetails("price", e.target.value)
                                }
                                    value={this.state.newSKU.price || ''} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-group">
                                <label for="input">External ID</label>
                                <input type="text" className="form-control"
                                    onChange={(e) =>
                                        this.updateSKUDetails("externalID", e.target.value)
                                    }
                                    value={this.state.newSKU.externalID || ''} />
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <label for="input" style={{whiteSpace: 'nowrap'}}>Minimum order quantity</label>
                            <input type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <label for="input">Additional charge</label>
                            <input type="text" className="form-control" />
                        </div>
                    </div>
                </div> */}
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
                            <button type="button" className="btn btn-secondary" onClick={e => this.createSKUs()}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 14.6667L4 14.6667C2.89543 14.6667 2 13.7712 2 12.6667V3.33333C2 2.22876 2.89543 1.33333 4 1.33333H8H10.0278C10.6098 1.33333 11.1629 1.58685 11.5428 2.02774L12.3979 3.02L13.4967 4.25964C13.821 4.62548 14 5.0974 14 5.58626L14 8V12.6667C14 13.7712 13.1046 14.6667 12 14.6667ZM11.3939 3.89744L10.5328 2.89813C10.4062 2.75117 10.2218 2.66667 10.0278 2.66667L8 2.66667L4 2.66667C3.63181 2.66667 3.33333 2.96514 3.33333 3.33333L3.33333 12.6667C3.33333 13.0349 3.63181 13.3333 4 13.3333L12 13.3333C12.3682 13.3333 12.6667 13.0349 12.6667 12.6667L12.6667 8V5.58626C12.6667 5.42331 12.607 5.266 12.4989 5.14405L11.3939 3.89744ZM5.66667 8.33333C5.66667 7.96514 5.96514 7.66667 6.33333 7.66667H7.33333V6.66667C7.33333 6.29848 7.63181 6 8 6C8.36819 6 8.66667 6.29848 8.66667 6.66667V7.66667H9.66667C10.0349 7.66667 10.3333 7.96514 10.3333 8.33333C10.3333 8.70152 10.0349 9 9.66667 9H8.66667V10C8.66667 10.3682 8.36819 10.6667 8 10.6667C7.63181 10.6667 7.33333 10.3682 7.33333 10V9H6.33333C5.96514 9 5.66667 8.70152 5.66667 8.33333Z" fill=""></path>
                                </svg>
                                Create SKU</button>
                        </div>
                    </div>
                </>}
                <>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>SKU Name</th>
                                <th>SKU Code</th>
                                <th>Attributes</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {product.skus && product.skus.map((sku, index) => (
                                <tr key={"skuItem-" + index}>
                                    <td>{sku.skuName}</td>
                                    <td>{sku.skuCode}</td>
                                    <td>{sku.attributes && sku.attributes.map((attr, index) => {
                                        let attrs = this.props.attributes.find(attrb => attrb._id == attr.attr_type);
                                        if (attrs && attrs.values) {
                                            let value = attrs.values.find(val => val._id == attr.attr_val)
                                            return <><span>{attrs.name} : <b>{value.attr_name}</b></span> <br /></>
                                        }
                                        else {
                                            return <></>
                                        }
                                    })
                                    }</td>
                                    <td>{sku.price}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </>
                <Modal
                    show={this.state.showAttributes}
                    animation={false}
                    onHide={(e) => this.closePopup()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Select Attributes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-12">
                                {this.props.attributes &&
                                    this.props.attributes.map((attribute, index) => {
                                        return (
                                            <div className="pure-checkbox" key={"attr=" + index}>
                                                <input
                                                    id={"checkbox" + attribute._id}
                                                    name={"checkbox" + attribute._id}
                                                    type="checkbox"
                                                    checked={this.state.attributesSelected.filter(attr => attr._id == attribute._id).length > 0}
                                                    onChange={(e) =>
                                                        this.selectAttribute(e, attribute)
                                                    }
                                                // defaultChecked={
                                                //   this.props.selectedCategories.indexOf(
                                                //     attribute._id
                                                //   ) > -1
                                                // }
                                                />
                                                <label htmlFor={"checkbox" + attribute._id}>
                                                    {attribute.name}
                                                </label>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={(e) => this.closePopup()}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={(e) => {
                                this.props.updateDetails(
                                    "attributes",
                                    this.state.attributesSelected
                                );
                                this.closePopup();
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}