import axios from "axios";
import CONFIG from "../config";

export const login = async (bodyObj) => {
    try {
        let response = await axios.post(
            CONFIG.BASEURL + "/admin/users/login",
            bodyObj
        );
        if (response.data.success === true) {
            return response.data.user;
        }
        else {
            throw response.data;
        }
    } catch (err) {
        throw err;
    }
};