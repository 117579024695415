import * as React from "react";

export default class CategoriesPagination extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="products-tools row mb-4">
          <div className="products-sortby products-sortby-xs pull-left">
            <div className="dropdown">
              {" "}
              <span>Filter By</span>
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {" "}
                Site name <span className="mdi mdi-chevron-down"></span>{" "}
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li>
                  <a href="#" title="Site name">
                    Site name
                  </a>
                </li>
                <li>
                  <a href="#" title="Domain name">
                    Domain name
                  </a>
                </li>
                <li>
                  <a href="#" title="Start date">
                    Start date
                  </a>
                </li>
                <li>
                  <a href="#" title="End date">
                    End date
                  </a>
                </li>
              </ul>
            </div>
            <div className="dropdown">
              {" "}
              <span>Show Per Page</span>
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {" "}
                12 <span className="mdi mdi-chevron-down mdi-xs"></span>{" "}
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li>
                  <a href="#" title="16">
                    16
                  </a>
                </li>
                <li>
                  <a href="#" title="20">
                    20
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="products-grid-list pull-right hidden-xs">
            <nav aria-label="Page navigation">
              <ul className="pagination m-0">
                <li>
                  {" "}
                  <a href="#" aria-label="Previous">
                    {" "}
                    <span aria-hidden="true">
                      <i className="mdi mdi-chevron-left"></i>
                    </span>{" "}
                  </a>{" "}
                </li>
                <li>
                  <a href="#">1</a>
                </li>
                <li>
                  <a href="#">2</a>
                </li>
                <li>
                  <a href="#">3</a>
                </li>
                <li>
                  {" "}
                  <a href="#" aria-label="Next">
                    {" "}
                    <span aria-hidden="true">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>{" "}
                  </a>{" "}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
