import { LIST_ATTRIBUTES, ADD_ATTRIBUTE, DELETE_ATTRIBUTE } from "./attributes.types";

const INITIAL_STATE = {
    attributes: [],
};

const attributeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LIST_ATTRIBUTES:
            return { ...state, attributes: [...action.payload] };
        case ADD_ATTRIBUTE:
            let existingAttributes = state.attributes;
            if(action.payload.action === 'update') {
                let index = existingAttributes.findIndex(attr=>action.payload.attributes._id === attr._id);
                if(index > -1){
                    existingAttributes.splice(index,1);
                }
            }
            return { ...state, attributes: [...existingAttributes, action.payload.attributes] };
        case DELETE_ATTRIBUTE:
            console.log(action.payload)
            return {...state, attributes:action.payload.attributes}
            default:
            return state;
    }
}

export default attributeReducer;