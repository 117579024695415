import * as React from "react";
import Titlebox from "../common/Titlebox";
import { Modal, Button, Form, Table } from 'react-bootstrap';
import {fetchOrders} from '../../api/orders'
import OrderList from "./OrderList";

class TrackOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders:[]
        }
    }

    componentDidMount() {
        this.getOrders();
    }

    componentDidUpdate() {

    }

    async getOrders() {
        try {
            let orders = await fetchOrders();
            this.setState({ orders: orders })
        }
        catch (err) {

        }
    }

    render() {
        return (
            <div id="db-page-wrapper">
                <div className="page-content-tab">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <Titlebox title="Track Order" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <div className="pull-left">
                                        

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        {this.state.orders.length > 0 && (
                                            <OrderList orders={this.state.orders} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer id="footer" className="text-left">
                        &copy; <script>document.write(new Date().getFullYear())</script>{" "}
                        eComchain Inc. All rights reserved.
                    </footer>
                </div>
            </div>
        );
    }
}

export default TrackOrder;
