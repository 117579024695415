
import * as React from "react";
import { Modal, Button } from "react-bootstrap";

export default class Stock extends React.Component {
    constructor(props) {
        super(props);
        this.state = { stock:props.product.stock || [], newStock:{} };
    }
    componentDidMount() { }

    componentDidUpdate(prevProps){
        if(prevProps.product && prevProps.product != this.props.product){
            this.setState({stock:this.props.product.stock});
        }
    }

    createStock(){
        const {newStock, stock} = this.state;
        let index = stock.findIndex(st=>st.sku==newStock.sku);
        if(index>-1){
            stock[index].stock =  stock[index].stock+newStock.stock;
            this.setState({stock:[...stock]},()=>{
                this.props.updateDetails('stock',this.state.stock);
                this.setState({newStock:{}})
            });
        }
        else {
            this.setState({stock:[...stock,newStock]},()=>{
        
                this.props.updateDetails('stock',this.state.stock);
                this.setState({newStock:{}})
            });
        }
        
    }    
    updateStock(key,value){
        let newStock = this.state.newStock
        if(key){
            if(key=='stock'){
                newStock[key] = parseInt(value);
                if(parseInt(value)>0){
                    newStock['status']='in_stock';  
                }
            }
            else{
            newStock[key] = value
            }
        }
        this.setState({newStock:newStock})
    }

    render() {
        const {product} = this.props;
        const {skus} = product;
        const {stock} =  this.state
        return (
            <>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <label for="input">Product SKU</label>
                            <select className="select-db form-control" onChange={e=>this.updateStock('sku',e.target.value)}>
                                <option value="">Select</option>
                                {skus && skus.map((sku,index)=>(<option value={sku._id} key={"sku-"+index}>{sku.skuName}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <label for="input">Stock status<span className="mandatory">*</span></label>
                            <select className="select-db form-control" onChange={e=>this.updateStock('status',e.target.value)}>
                                <option value="">Select</option>
                                <option value="in_stock">In Stock</option>
                                <option value="out_of_stock">Out of Stock</option>
                            </select>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group">
                            <div className="pure-checkbox">
                                <input id="checkbox11" name="checkbox" type="checkbox" />
                                <label for="checkbox11">Do not allow back order</label>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <label for="input">Stock low qty alarm</label>
                            <input type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <label for="input">Verify qty on-hand (internal use)</label>
                            <input type="text" className="form-control" />
                        </div>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <label for="input">Add stock<span className="mandatory">*</span></label>
                            <input type="text" className="form-control" onChange={e=>this.updateStock('stock',e.target.value)}/>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="alert alert-info alert-dismissible"> <a href="#" className="close" data-dismiss="alert" aria-label="close"><i className="mdi mdi-close"></i></a> <i className="mdi mdi-alert-circle"></i> Select a supplier or warehouse to add this stock amount to </div>
                    </div>
                </div> */}
                <div className="row">
                    {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                            <label for="input">Supplier</label>
                            <input type="text" className="form-control" />
                            <button type="button" className="btn btn-secondary mt-4">
                                <svg className="icon-xs" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" fillRule="evenodd" d="M3.33333 1.33333H12.6667C13.7712 1.33333 14.6667 2.22876 14.6667 3.33333V12.6667C14.6667 13.7712 13.7712 14.6667 12.6667 14.6667H3.33333C2.22876 14.6667 1.33333 13.7712 1.33333 12.6667V3.33333C1.33333 2.22876 2.22876 1.33333 3.33333 1.33333ZM3.33333 2.66667C2.96514 2.66667 2.66667 2.96514 2.66667 3.33333V12.6667C2.66667 13.0349 2.96514 13.3333 3.33333 13.3333H12.6667C13.0349 13.3333 13.3333 13.0349 13.3333 12.6667V3.33333C13.3333 2.96514 13.0349 2.66667 12.6667 2.66667H3.33333ZM8.0003 4.50491C8.36849 4.50491 8.66697 4.80338 8.66697 5.17157V7.33334H10.8284C11.1966 7.33334 11.4951 7.63181 11.4951 8C11.4951 8.36819 11.1966 8.66667 10.8284 8.66667H8.66697V10.8284C8.66697 11.1966 8.36849 11.4951 8.0003 11.4951C7.63211 11.4951 7.33364 11.1966 7.33364 10.8284V8.66667H5.17157C4.80338 8.66667 4.50491 8.36819 4.50491 8C4.50491 7.63181 4.80338 7.33334 5.17157 7.33334H7.33364V5.17157C7.33364 4.80338 7.63211 4.50491 8.0003 4.50491Z" ></path>
                                </svg>
                                Add </button>
                        </div>
                    </div> */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group">
                            {/* <label for="input">Warehouse<span className="mandatory">*</span></label>
                            <input type="text" className="form-control" /> */}
                            <button type="button" className="btn btn-secondary mt-4" onClick={e=>this.createStock()}>
                                <svg className="icon-xs" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.33333 1.33333H12.6667C13.7712 1.33333 14.6667 2.22876 14.6667 3.33333V12.6667C14.6667 13.7712 13.7712 14.6667 12.6667 14.6667H3.33333C2.22876 14.6667 1.33333 13.7712 1.33333 12.6667V3.33333C1.33333 2.22876 2.22876 1.33333 3.33333 1.33333ZM3.33333 2.66667C2.96514 2.66667 2.66667 2.96514 2.66667 3.33333V12.6667C2.66667 13.0349 2.96514 13.3333 3.33333 13.3333H12.6667C13.0349 13.3333 13.3333 13.0349 13.3333 12.6667V3.33333C13.3333 2.96514 13.0349 2.66667 12.6667 2.66667H3.33333ZM8.0003 4.50491C8.36849 4.50491 8.66697 4.80338 8.66697 5.17157V7.33334H10.8284C11.1966 7.33334 11.4951 7.63181 11.4951 8C11.4951 8.36819 11.1966 8.66667 10.8284 8.66667H8.66697V10.8284C8.66697 11.1966 8.36849 11.4951 8.0003 11.4951C7.63211 11.4951 7.33364 11.1966 7.33364 10.8284V8.66667H5.17157C4.80338 8.66667 4.50491 8.36819 4.50491 8C4.50491 7.63181 4.80338 7.33334 5.17157 7.33334H7.33364V5.17157C7.33364 4.80338 7.63211 4.50491 8.0003 4.50491Z" ></path>
                                </svg>
                                Add </button>
                        </div>
                    </div>
                    <>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                        <th>SKU Name</th>
                        <th>Stock</th>
                        </tr>
                    </thead>
                    <tbody>
                    {stock && stock.map((stock,index)=>(
<tr key={"stock-"+index}>
<td>{product.skus && product.skus.find(sku=>sku._id == stock.sku).skuName}</td>
<td>{stock.stock}</td>

</tr>
                    )) }
                        
                    </tbody>
                </table>
</>
                </div>
            </>
        )
    }
}