import * as React from "react";
import './login.css'
import { connect } from "react-redux";
import { saveUser } from "../../redux/users/users.action"
import Cookies from 'universal-cookie';
import { login } from "../../api/auth";
import { NotificationManager } from "react-notifications";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { credentials: { email: "", password: "" } };
  }

  componentDidMount() {
    this.cookies = new Cookies();
    const email = this.cookies.get('email');
    const accessToken = this.cookies.get('accessToken');
    const name = this.cookies.get('name');
    if (email && accessToken) {
      const user = {
        email: email,
        token: accessToken,
        name: name
      }
      this.props.saveUser(user);
      window.location.href = "/products";
    }
  }

  updateCredentials(key, value) {
    const credentials = this.state.credentials;
    credentials[key] = value;
    this.setState({ credentials: credentials })
  }

  async login() {
    try {
      let result = await login(this.state.credentials);
      if (result) {
        NotificationManager.success("Login Successful", "Success");
        const user = {
          email: result.email,
          token: result.token,
          name: result.firstname + " " + result.lastname
        }
        this.props.saveUser(user);
        this.cookies.set('email', result.email, { path: '/' });
        this.cookies.set('accessToken', result.token, { path: '/' });
        this.cookies.set('name', result.firstname + " " + result.lastname, { path: '/' })
        this.setState({ credentials: { email: "", password: "" } })
        window.location.href = "/products";
      }
      else {
        NotificationManager.error('Something went wrong', "Error");
      }
    } catch (err) {
      if (err.success === false && err.message) {
        NotificationManager.error(err.message, "Error");
      }
      else {
        NotificationManager.error('Something went wrong', "Error");
      }
    }
  }

  render() {
    const { credentials } = this.state;
    return (<>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <form >
            <h3>Sign In</h3>
            <div className="mb-3">
              <label>Email address</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                onChange={e => this.updateCredentials('email', e.target.value)}
                value={credentials.email || ""}
              />
            </div>
            <div className="mb-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                onChange={e => this.updateCredentials('password', e.target.value)}
                value={credentials.password || ""}
              />
            </div>
            <div className="mb-3">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Remember me
                </label>
              </div>
            </div>
            <div className="d-grid">
              <button type="button" className="btn btn-primary" onClick={e => this.login()}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>)
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.users.loggedInUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUser: (user) => dispatch(saveUser(user))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);