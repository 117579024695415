import * as React from "react";
import { withRouter } from "react-router";
import Pagination from "../common/Pagination";
import Titlebox from "../common/Titlebox";
import CategoriesList from "./categories-list";
import CategoriesPagination from "./categories-pagination";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchCategories, addCategory,updateCat } from "../../api/categories"
import {listCategories} from "../../redux/categories/categories.actions"

class Categories extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      addCategoryShow: false,
      updateCategoryShow: false,
      newCategory: {},
      updateCategory: {},
    };
  }

  async fetchCategories(bodyObj) {
    try {
      let categories = await fetchCategories(bodyObj)
      this.setState({ categories: categories });
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    let bodyObj = {};
    if (this.props.match.params.id) {
      bodyObj = { parent: this.props.match.params.id };
    }
  //  this.fetchCategories(bodyObj);
    this.props.listCategories(bodyObj);
  }

  handleClose = () => this.setState({ addCategoryShow: false });
  handleShow = (catId) =>
    this.setState({
      addCategoryShow: true,
      newCategory: { parent: catId !== "" && catId },
    });

  handleupdateClose = () => this.setState({ updateCategoryShow: false });
  handleupdateShow = (category) =>
    this.setState({
      updateCategoryShow: true,
      updateCategory: {
        name: category.name,
        type: category.type,
        id: category._id,
      },
    });

  onChange = (e, type) => {
    const content = {};
    content[type] = e.target.value;
    this.setState({ newCategory: { ...this.state.newCategory, ...content } });
  };

  onupdateChange = (e, type) => {
    const content = {};
    content[type] = e.target.value;
    this.setState({
      updateCategory: { ...this.state.updateCategory, ...content },
    });
  };

  createCategory = async () => {
    try {
      let response = await addCategory(this.state.newCategory)
      console.log(response);
      this.setState({ addCategoryShow: false });
      this.fetchCategories();
    } catch (err) {}
  };

  updateCategory = async () => {
    try {
      let response = await updateCat(this.state.updateCategory);
      console.log(response);
      this.setState({ updateCategoryShow: false });
      this.fetchCategories();
    } catch (err) {}
  };

  render() {
    return (
      <div id="db-page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <Titlebox title="Catalog Managment" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-rounded">
                  <div className="card-body">
                    <div className="pull-left">
                      <button
                        type="button"
                        className="btn btn-primary"
                        title="Add Catalog"
                        onClick={(e) => this.handleShow("")}
                      >
                        Add Catalog
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary  ml-0 ml-md-4 ml-lg-4"
                        title="Update"
                      >
                        <span className="hidden-xs">Update</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary  ml-0 ml-md-4 ml-lg-4"
                        title="Duplicate"
                      >
                        <span className="hidden-xs">Duplicate</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary ml-0 ml-md-4 ml-lg-4"
                        title="Delete"
                      >
                        <span className="hidden-xs">Delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-rounded">
                  <div className="card-body">
                    <CategoriesPagination />
                    <CategoriesList
                      categories={this.props.categories}
                      handleShow={this.handleShow}
                      handleupdateShow={this.handleupdateShow}
                    />
                    <Pagination />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer id="footer" className="text-left">
            &copy; <script>document.write(new Date().getFullYear())</script>{" "}
            eComchain Inc. All rights reserved.
          </footer>
        </div>

        <Modal
          show={this.state.addCategoryShow}
          onHide={this.handleClose}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="input">
                    Name<span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.onChange(e, "name");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="input">
                    Category type<span className="mandatory">*</span>
                  </label>
                  <select
                    className="select-db form-control"
                    onChange={(e) => {
                      this.onChange(e, "type");
                    }}
                  >
                    <option value="PRODUCT">PRODUCT</option>
                    <option value="CONTENT">CONTENT</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.createCategory}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.updateCategoryShow}
          onHide={this.handleupdateClose}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="input">
                    Name<span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={this.state.updateCategory.name}
                    onChange={(e) => {
                      this.onupdateChange(e, "name");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="input">
                    Category type<span className="mandatory">*</span>
                  </label>
                  <select
                    className="select-db form-control"
                    onChange={(e) => {
                      this.onupdateChange(e, "type");
                    }}
                    defaultValue={this.state.updateCategory.type}
                  >
                    <option value="PRODUCT">PRODUCT</option>
                    <option value="CONTENT">CONTENT</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleupdateClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.updateCategory}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.categories.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listCategories: (params) => dispatch(listCategories(params))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Categories));

