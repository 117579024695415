import { SELECT_PRODUCT, LIST_PRODUCTS,GET_PRODUCT } from "./products.types";

const INITIAL_STATE = {
  selectedProducts: [],
  products: [],
  selectedProduct:[]
};

const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_PRODUCT:
      let returnData = {};
      if (action.payload.checked) {
        returnData = {
          ...state,
          selectedProducts: [...state.selectedProducts, action.payload.data],
        };
      } else {
        state.selectedProducts.splice(
          state.selectedProducts.indexOf(action.payload.data),
          1
        );
        returnData = {
          ...state,
          selectedProducts: [...state.selectedProducts],
        };
      }
      return returnData;
    case LIST_PRODUCTS:
      return { ...state, products: [...action.payload] };
    case GET_PRODUCT:
      return {...state, selectedProduct:{...action.payload}}
    default:
      return state;
  }
};

export default productReducer;
