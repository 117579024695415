import { LIST_CATEGORIES,DELETE_CATEGORY } from "./categories.types";

const INITIAL_STATE = {
  deleteProducts: [],
  categories: [],
};

const categoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DELETE_CATEGORY:
      let returnData = {};
      if (action.payload.checked) {
        returnData = {
          ...state,
          deleteProducts: [...state.deleteProducts, action.payload.data],
        };
      } else {
        state.deleteProducts.splice(
          state.deleteProducts.indexOf(action.payload.data),
          1
        );
        returnData = {
          ...state,
          deleteProducts: [...state.deleteProducts],
        };
      }
      return returnData;
    case LIST_CATEGORIES:
      return { ...state, categories: [...action.payload] };
    default:
      return state;
  }
};

export default categoryReducer;
