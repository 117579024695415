import axios from "axios";
import CONFIG from "../config";

export const getAllAdminUsers = async () => {
    try {
            let response = await axios.get(
                CONFIG.BASEURL + "/admin/users/getAllUsers"
            );
            if (response.data.success === true) {
                return response.data.users;
            }
            else {
                throw response.data;
            }
    }
    catch (err) {
        throw err;
    }
}

export const saveUser = async (bodyObj) => {
    try {
        let response;
        if (bodyObj._id) {
            response = await axios.put(
                CONFIG.BASEURL + "/admin/users/update/" + bodyObj._id, bodyObj
            );
        }
        else {
            response = await axios.post(
                CONFIG.BASEURL + "/admin/users/register", bodyObj
            );
        }

        if (response.data.success === true) {
            return response.data.user;
        }
        else {
            throw response.data;
        }
    } catch (err) {
        throw err;
    }
};

export const deleteAdminUser = async (bodyObj) => {
    try {
        let response = await axios.post(
            CONFIG.BASEURL + "/admin/users/delete", bodyObj
        );
        if (response.data.success === true) {
            return response.data;
        }
        else {
            throw response.data;
        }
    } catch (err) { }
}