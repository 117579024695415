import * as React from "react";
import Cookies from 'universal-cookie';

export default class Topbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = { open: false, name: '' };
  }

  componentDidMount() {
    this.cookies = new Cookies();
    const email = this.cookies.get('email');
    const accessToken = this.cookies.get('accessToken');
    const name = this.cookies.get('name');
    this.setState({ name: name })
    if (!email || !accessToken) {
      this.cookies.remove('email');
      this.cookies.remove('accessToken');
      this.cookies.remove('name');
      window.location.href = "/";
    }
  }

  logout() {
    this.cookies.remove('email');
    this.cookies.remove('accessToken');
    this.cookies.remove('name');
    window.location.href = "/";
  }

  render() {
    return (
      <div id="db-topbar">
        <nav className="navbar-custom">
          <ul className="list-unstyled topbar-nav pull-right">
            {/* <li className="dropdown">
              {" "}
              <a
                className="nav-link"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.66667 5V12.5C1.66667 13.8807 2.78596 15 4.16667 15L15.8333 15C17.214 15 18.3333 13.8807 18.3333 12.5V5C18.3333 3.61929 17.214 2.5 15.8333 2.5H4.16667C2.78596 2.5 1.66667 3.61929 1.66667 5ZM4.16667 13.3333C3.70643 13.3333 3.33334 12.9602 3.33334 12.5L3.33333 5C3.33333 4.53976 3.70643 4.16667 4.16667 4.16667L15.8333 4.16667C16.2936 4.16667 16.6667 4.53976 16.6667 5V12.5C16.6667 12.9602 16.2936 13.3333 15.8333 13.3333L4.16667 13.3333ZM5 15.8333C4.53976 15.8333 4.16667 16.2064 4.16667 16.6667C4.16667 17.1269 4.53976 17.5 5 17.5H15C15.4602 17.5 15.8333 17.1269 15.8333 16.6667C15.8333 16.2064 15.4602 15.8333 15 15.8333H5Z"
                    fill="#055DE0"
                  />
                </svg>
              </a>{" "}
            </li>
            <li className="dropdown">
              {" "}
              <a
                className="nav-link"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.5 5C2.5 3.61929 3.61929 2.5 5 2.5H15C16.3807 2.5 17.5 3.61929 17.5 5V6.66667C17.5 7.30696 17.2593 7.89104 16.8634 8.33334C17.2593 8.77563 17.5 9.3597 17.5 10V11.6667C17.5 13.0474 16.3807 14.1667 15 14.1667H10.8333V15.8333H13.75H17.5C17.9602 15.8333 18.3333 16.2064 18.3333 16.6667C18.3333 17.1269 17.9602 17.5 17.5 17.5H13.75H10H6.25H2.5C2.03976 17.5 1.66667 17.1269 1.66667 16.6667C1.66667 16.2064 2.03976 15.8333 2.5 15.8333H6.25H9.16667V14.1667H5C3.61929 14.1667 2.5 13.0474 2.5 11.6667V10C2.5 9.3597 2.74071 8.77563 3.13658 8.33334C2.74071 7.89104 2.5 7.30696 2.5 6.66667V5ZM5 7.5H15C15.4602 7.5 15.8333 7.1269 15.8333 6.66667V5C15.8333 4.53976 15.4602 4.16667 15 4.16667H5C4.53976 4.16667 4.16667 4.53976 4.16667 5V6.66667C4.16667 7.1269 4.53976 7.5 5 7.5ZM10 12.5H15C15.4602 12.5 15.8333 12.1269 15.8333 11.6667V10C15.8333 9.53976 15.4602 9.16667 15 9.16667H5C4.53976 9.16667 4.16667 9.53976 4.16667 10V11.6667C4.16667 12.1269 4.53976 12.5 5 12.5H10ZM5 10.8333C5 10.3731 5.3731 10 5.83333 10H8.33333C8.79357 10 9.16667 10.3731 9.16667 10.8333C9.16667 11.2936 8.79357 11.6667 8.33333 11.6667H5.83333C5.3731 11.6667 5 11.2936 5 10.8333ZM10.8333 10C10.3731 10 10 10.3731 10 10.8333C10 11.2936 10.3731 11.6667 10.8333 11.6667H13.3333C13.7936 11.6667 14.1667 11.2936 14.1667 10.8333C14.1667 10.3731 13.7936 10 13.3333 10H10.8333ZM5 5.83333C5 5.3731 5.3731 5 5.83333 5C6.29357 5 6.66667 5.3731 6.66667 5.83333C6.66667 6.29357 6.29357 6.66667 5.83333 6.66667C5.3731 6.66667 5 6.29357 5 5.83333ZM8.33333 5C7.8731 5 7.5 5.3731 7.5 5.83333C7.5 6.29357 7.8731 6.66667 8.33333 6.66667H10.8333C11.2936 6.66667 11.6667 6.29357 11.6667 5.83333C11.6667 5.3731 11.2936 5 10.8333 5H8.33333Z"
                    fill="#055DE0"
                  />
                </svg>
              </a>{" "}
            </li>
            <li className="dropdown">
              {" "}
              <a
                className="nav-link"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.8333 1.66667H4.16667C2.78596 1.66667 1.66667 2.78595 1.66667 4.16667V15.8333C1.66667 17.214 2.78596 18.3333 4.16667 18.3333H15.8333C17.214 18.3333 18.3333 17.214 18.3333 15.8333V4.16667C18.3333 2.78595 17.214 1.66667 15.8333 1.66667ZM3.33333 4.16667C3.33333 3.70643 3.70643 3.33333 4.16667 3.33333H15.8333C16.2936 3.33333 16.6667 3.70643 16.6667 4.16667V15.8333C16.6667 16.2936 16.2936 16.6667 15.8333 16.6667H4.16667C3.70643 16.6667 3.33333 16.2936 3.33333 15.8333V4.16667ZM10.0912 6.94658C9.57974 6.60563 8.92217 6.57384 8.38024 6.86388C7.83831 7.15391 7.5 7.71867 7.5 8.33333V11.6667C7.5 12.2813 7.83831 12.8461 8.38024 13.1361C8.92217 13.4262 9.57974 13.3944 10.0912 13.0534L12.5912 11.3868C13.0548 11.0776 13.3333 10.5573 13.3333 10C13.3333 9.44274 13.0548 8.92236 12.5912 8.61325L10.0912 6.94658ZM10.1644 11.0015L9.16667 11.6667V10.3364V9.66358V8.33333L10.1644 8.99846L10.8333 9.44444L11.6667 10L10.8333 10.5556L10.1644 11.0015Z"
                    fill="#055DE0"
                  />
                </svg>
              </a>{" "}
            </li> */}
            <li className={this.state.open ? 'dropdown open' : 'dropdown'}>
              <a
                className="nav-link dropdown-toggle nav-user"
                data-toggle="dropdown"
                onClick={e => this.setState({ open: !this.state.open })}
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <span className="nav-user-name hidden-xs">{this.state.name}</span>
                <img
                  src="/images/users/92x92.png"
                  alt="profile-user"
                  className="rounded-circle"
                />
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                {/* <a className="dropdown-item" href="#">
                  <i className="dripicons-user text-muted mr-2"></i> Profile
                </a>
                <a className="dropdown-item" href="#">
                  <i className="dripicons-lock text-muted mr-2"></i> Lock screen
                </a> */}
                {/* <div className="dropdown-divider"></div> */}
                <a className="dropdown-item" onClick={e => this.logout()}>
                  <i className="dripicons-exit text-muted mr-2"></i> Logout
                </a>
              </div>
            </li>
          </ul>
          <ul className="list-unstyled topbar-nav">
            <li>
              <a href="#">
                <span className="responsive-logo">
                  <svg
                    width="46"
                    height="23"
                    viewBox="0 0 46 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="46" height="23" rx="11.5" fill="white" />
                    <path
                      d="M20.8709 4.4807H12.4662C12.3848 4.47617 12.3032 4.47617 12.2218 4.4807C11.3688 4.66541 5.73744 6.1281 6.49561 12.6478C6.49561 12.6478 7.20888 17.4302 11.5584 17.9894H11.708H16.8705C17.7811 17.8897 18.4455 17.0806 18.3669 16.1672C18.3669 16.1672 18.3669 14.4999 16.9653 14.4999H12.5709C10.9824 14.2528 9.82096 12.8686 9.8525 11.26C9.89172 9.69333 10.9286 8.32774 12.4263 7.87034C12.527 7.82975 12.6327 7.80289 12.7405 7.79047L19.594 7.76551L20.6863 7.80045C20.6863 7.80045 23.3349 8.55925 23.4497 11.8191C23.529 12.5549 24.1517 13.1113 24.8912 13.1071C26.218 13.147 26.5971 11.7442 26.5971 11.7442C26.5971 11.7442 27.5946 6.02825 21.0305 4.52064L20.8709 4.4807Z"
                      fill="#11426D"
                    />
                    <path
                      d="M24.6118 18.0193H33.0315C33.1129 18.0238 33.1945 18.0238 33.2759 18.0193C34.1289 17.8346 39.7603 16.3719 39.0021 9.85221C39.0021 9.85221 38.2888 5.06977 33.9393 4.51065H33.7897H28.6222C27.7116 4.61034 27.0472 5.41941 27.1258 6.33277C27.1258 6.33277 27.1258 8.00014 28.5274 8.00014H32.9218C34.514 8.25262 35.6726 9.64712 35.6302 11.26C35.591 12.8266 34.5541 14.1922 33.0565 14.6496C32.9557 14.6902 32.8501 14.7171 32.7422 14.7295L25.8888 14.7545L24.7964 14.7195C24.7964 14.7195 22.1478 13.9607 22.0331 10.7009C21.9538 9.96505 21.331 9.40864 20.5916 9.4129C19.2648 9.37297 18.8857 10.7758 18.8857 10.7758C18.8857 10.7758 17.8881 16.4917 24.4522 17.9993L24.6118 18.0193Z"
                      fill="#F80D19"
                    />
                  </svg>
                </span>
              </a>
            </li>
            <li className="display-enlarge hidden-xs hidden-sm hidden-md">
              <button className="button-menu-mobile nav-link text-center">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="16" cy="16" r="16" fill="#0A6CFF" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 9.33333H20C21.4728 9.33333 22.6667 10.5272 22.6667 12V20C22.6667 21.4728 21.4728 22.6667 20 22.6667H12C10.5272 22.6667 9.33333 21.4728 9.33333 20V12C9.33333 10.5272 10.5272 9.33333 12 9.33333ZM21.3333 12C21.3333 11.2636 20.7364 10.6667 20 10.6667H12C11.2636 10.6667 10.6667 11.2636 10.6667 12V20C10.6667 20.7364 11.2636 21.3333 12 21.3333H20C20.7364 21.3333 21.3333 20.7364 21.3333 20V12ZM15.8047 13.5286C15.5444 13.2682 15.1223 13.2682 14.8619 13.5286C14.6016 13.7889 14.6016 14.2111 14.8619 14.4714L16.3905 16L14.8619 17.5286C14.6016 17.7889 14.6016 18.2111 14.8619 18.4714C15.1223 18.7318 15.5444 18.7318 15.8047 18.4714L17.8047 16.4714C18.0651 16.2111 18.0651 15.7889 17.8047 15.5286L15.8047 13.5286Z"
                    fill="white"
                  />
                </svg>
              </button>
            </li>
            <li className="app-search hidden-xs">
              <form role="search" className="">
                <input
                  type="text"
                  id="AllCompo"
                  placeholder="Search..."
                  className="form-control"
                />
                <a href="#">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 1.5C0 0.671573 0.671573 0 1.5 0H3C3.33137 0 3.6 0.268629 3.6 0.6C3.6 0.931371 3.33137 1.2 3 1.2H1.5C1.33431 1.2 1.2 1.33431 1.2 1.5V3C1.2 3.33137 0.931371 3.6 0.6 3.6C0.268629 3.6 0 3.33137 0 3V1.5ZM9.6 5.4C9.6 7.05685 8.25685 8.4 6.6 8.4C6.06663 8.4 5.56577 8.26081 5.13176 8.01677L3.72426 9.42426C3.48995 9.65858 3.11005 9.65858 2.87574 9.42426C2.64142 9.18995 2.64142 8.81005 2.87574 8.57574L4.22211 7.22936C3.83201 6.72303 3.6 6.08861 3.6 5.4C3.6 3.74315 4.94315 2.4 6.6 2.4C8.25685 2.4 9.6 3.74315 9.6 5.4ZM8.4 5.4C8.4 4.40589 7.59411 3.6 6.6 3.6C5.60589 3.6 4.8 4.40589 4.8 5.4C4.8 6.39411 5.60589 7.2 6.6 7.2C7.59411 7.2 8.4 6.39411 8.4 5.4ZM10.5 0C11.3284 0 12 0.671573 12 1.5V3C12 3.33137 11.7314 3.6 11.4 3.6C11.0686 3.6 10.8 3.33137 10.8 3V1.5C10.8 1.33431 10.6657 1.2 10.5 1.2H9C8.66863 1.2 8.4 0.931371 8.4 0.6C8.4 0.268629 8.66863 0 9 0H10.5ZM0 10.5C0 11.3284 0.671573 12 1.5 12H3C3.33137 12 3.6 11.7314 3.6 11.4C3.6 11.0686 3.33137 10.8 3 10.8H1.5C1.33431 10.8 1.2 10.6657 1.2 10.5V9C1.2 8.66863 0.931371 8.4 0.6 8.4C0.268629 8.4 0 8.66863 0 9V10.5ZM10.5 12C11.3284 12 12 11.3284 12 10.5V9C12 8.66863 11.7314 8.4 11.4 8.4C11.0686 8.4 10.8 8.66863 10.8 9V10.5C10.8 10.6657 10.6657 10.8 10.5 10.8H9C8.66863 10.8 8.4 11.0686 8.4 11.4C8.4 11.7314 8.66863 12 9 12H10.5Z"
                      fill="#055DE0"
                    />
                  </svg>
                </a>
              </form>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
