import * as React from "react";

export default class OrderList extends React.Component {
    constructor(props) {
        super(props)
        this.loadOptions = this.loadOptions.bind(this)
        this.state = {
            statuses : [
                {
                    index: 1,
                    status: "Confirmed"
                },
                {
                    index: 2,
                    status: "Shipped"
                },
                {
                    index: 3,
                    status: "Delivered"
                },
                {
                    index: 4,
                    status: "Return Initiated"
                },
                {
                    index: 5,
                    status: "Returned"
                },
                {
                    index: 6,
                    status: "Refunded"
                },
            ]
        }
    }

    loadOptions(status) {

        let index = this.state.statuses.findIndex(stat => stat.status == status);
        if (index > -1) {
            if (index == this.state.statuses.length - 1) {
                return <span>Refunded</span>
            }
            else {
                return (<button className="btn btn-primary">Mark as {this.state.statuses[index + 1].status}</button>)
            }
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="table-responsive">
                        <table className="product-list-view table table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th className="text-center">Select </th>
                                    <th className="text-center">
                                        Order Number
                                        <i
                                            className="ml-2 mdi mdi-alert-circle-outline"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="Order Number"
                                        ></i>
                                    </th>
                                    <th className="text-center">
                                        Order Date
                                        <i
                                            className="ml-2 mdi mdi-alert-circle-outline"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="Order Date"
                                        ></i>
                                    </th>
                                    <th className="text-center">
                                        Order Status
                                        <i
                                            className="ml-2 mdi mdi-alert-circle-outline"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title="Order Status"
                                        ></i>
                                    </th>
                                    <th className="text-center">
                                        Update Status
                                        <i
                                            className="ml-2 mdi mdi-alert-circle-outline"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title="Update Status"
                                        ></i>
                                    </th>
                                    <th className="text-center">
                                        Action
                                        <i
                                            className="ml-2 mdi mdi-alert-circle-outline"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title="Edit details"
                                        ></i>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {this.props.orders &&
                                    this.props.orders.map((order, index) => {
                                        return (
                                            <tr>
                                                <td className="text-center">
                                                    <div className="pure-checkbox text-center">
                                                        <input
                                                            id={"checkbox" + order._id}
                                                            name="checkbox"
                                                            type="checkbox"
                                                        //   onChange={e=>this.props.select(user._id,e.target.checked)}
                                                        />
                                                        <label
                                                            htmlFor={"checkbox" + order._id}
                                                            className="m-0"
                                                        ></label>
                                                    </div>
                                                </td>
                                                <td className="text-center">{order.orderNumber}</td>
                                                <td className="text-center">{order.createdAt}</td>
                                                <td className="text-center">{order.orderStatus}</td>
                                                <td className="text-center">{this.loadOptions(order.orderStatus)}</td>
                                                <td className="text-center"><a href={"orderDetails/"+order._id}>View</a></td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
