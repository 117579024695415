import * as React from "react";
import ProductGrid from "./product-grid-view";
import ProductList from "./product-list-view";
import ProductTools from "./product-tools";
import Titlebox from "../common/Titlebox";
import Pagination from "../common/Pagination";
import { connect } from "react-redux";
import { fetchProducts } from "../../api/products"
import { listProducts, deleteProduct } from "../../redux/products/products.actions";

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = { gridview: true, products: [] };
    this.switchView = this.switchView.bind(this);
  }
  switchView(type) {
    if (type === "list") {
      this.setState({ gridview: false });
    } else {
      this.setState({ gridview: true });
    }
  }

  async fetchProducts() {
    try {
      let response = await fetchProducts()
      this.setState({ products: response.data.products });
    } catch (err) { }
  }

  componentDidMount() {
    this.props.listProducts();
  }
  render() {
    return (
      <div id="db-page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <Titlebox title="Products" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-rounded">
                  <div className="card-body">
                    <div className="pull-left">
                      <button
                        type="button"
                        className="btn btn-primary"
                        title="Add Product"
                        onClick={(e) => (window.location.href = "/add-product")}
                      >
                        Add Product
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary ml-0 ml-md-4 ml-lg-4"
                        title="Delete"
                        data-toggle="modal"
                        data-target="#delete"
                      >
                        <span
                          className="hidden-xs"
                          onClick={(e) => {
                            this.props.deleteProducts(this.props.selectedProducts)
                          }}
                        >
                          Delete
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary  ml-0 ml-md-4 ml-lg-4"
                        title="Search"
                      >
                        <span className="hidden-xs">Search</span>
                      </button>
                    </div>
                  </div>
                  <div className="modal fade" id="delete" role="dialog">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body">
                          <div className="modal-head">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              <i className="mdi mdi-close"></i>
                            </button>
                            <h4>Delete</h4>
                          </div>
                          <div className="modal-copy">
                            <div className="row">
                              <div className="col-lg-12">
                                <p>
                                  Do you want to delete the selected product(s)?
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 text-right">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                title="Close"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.8047 3.13807C14.0651 2.87772 14.0651 2.45561 13.8047 2.19526C13.5444 1.93491 13.1223 1.93491 12.8619 2.19526L8 7.05719L3.13807 2.19526C2.87772 1.93491 2.45561 1.93491 2.19526 2.19526C1.93491 2.45561 1.93491 2.87772 2.19526 3.13807L7.05719 8L2.19526 12.8619C1.93491 13.1223 1.93491 13.5444 2.19526 13.8047C2.45561 14.0651 2.87772 14.0651 3.13807 13.8047L8 8.94281L12.8619 13.8047C13.1223 14.0651 13.5444 14.0651 13.8047 13.8047C14.0651 13.5444 14.0651 13.1223 13.8047 12.8619L8.94281 8L13.8047 3.13807Z"
                                    fill=""
                                  ></path>
                                </svg>
                                No{" "}
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary ml-4"
                                title="Select"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.774 4.21342C14.0704 4.50282 14.076 4.97766 13.7866 5.274L6.46237 12.774C6.31479 12.9251 6.11032 13.007 5.89922 12.9995C5.68812 12.992 5.48997 12.8959 5.35346 12.7347L2.17768 8.98469C1.90999 8.6686 1.94923 8.19535 2.26532 7.92766C2.58141 7.65997 3.05466 7.69921 3.32235 8.0153L5.96528 11.1361L12.7134 4.226C13.0028 3.92965 13.4777 3.92402 13.774 4.21342Z"
                                    fill="white"
                                  ></path>
                                </svg>
                                Yes{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-rounded">
                  <div className="card-body">
                    <ProductTools switchView={this.switchView} />
                    <Pagination />
                    {this.props.products.length > 0 && (
                      <>
                        {this.state.gridview ? (
                          <ProductGrid products={this.props.products} />
                        ) : (
                          <ProductList products={this.props.products} />
                        )}
                      </>
                    )}
                    <Pagination />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer id="footer" className="text-left">
            &copy; <script>document.write(new Date().getFullYear())</script>{" "}
            eComchain Inc. All rights reserved.
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedProducts: state.products.selectedProducts,
    products: state.products.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listProducts: () => dispatch(listProducts()),
    deleteProducts: (productIds) => dispatch(deleteProduct(productIds))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
