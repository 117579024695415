import * as React from "react";
import Titlebox from "../common/Titlebox";
import BasicInfo from "./basic-info";
import { withRouter } from "react-router-dom"
import { connect } from "react-redux";
import {
  NotificationManager,
} from "react-notifications";
import PropTypes from "prop-types";
import { getProductfromAPI } from "../../redux/products/products.actions";
import AdvancedInfo from "./advanced-info";
import { fetchAttributes } from "../../api/attributes"
import { createProduct, updateProduct } from "../../api/products"
import { getAllLeafCategories } from "../../api/categories"
import ImageManagement from "./image-management";

class AddProduct extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = { categories: [], attributes: [], product: { categories: [], product_display: {}, sku: {}, stock: [], skus: [] } };
    this.updateDetails = this.updateDetails.bind(this);
  }

  async fetchCategories() {
    try {
      let categories = await getAllLeafCategories();
      this.setState({ categories: categories });
    } catch (err) { }
  }

  async fetchAttributeList() {
    try {
      let attributes = await fetchAttributes()
      this.setState({ attributes: attributes });
    } catch (err) { }
  }

  async componentDidMount() {
    this.fetchCategories();
    this.fetchAttributeList()

    if (this.props.match.params.id) {
      await this.props.getProductfromAPI(this.props.match.params.id)
      this.setState({ product: this.props.selectedProduct });
    }
  }

  updateDetails(key, value, subkey = '', type = '') {
    let product = this.state.product;
    if (subkey === '') {
      if (key === 'images') {
        if (type === "mainimage") {
          if (product[key] && product[key].length > 0) {
            product[key][0] = value;
          }
          else {
            product[key] = [value]
          }
        }
        else {
          product[key] = [product[key][0], ...value]
        }

      }
      else {
        product[key] = value;
      }
    }
    else {
      if(!product[subkey]){
        product[subkey] = {}
      }
      product[subkey][key] = value
    }
    this.setState({ product: product });
  }

  async submitAction(from='save') {
    try {
      const { product } = this.state;
      if (this.validateProduct(product)) {
        let response;
        if (product._id) {
          response = await updateProduct(product);
        }
        else {
          response = await createProduct(product);
        }
        if(from=='save') {
        if (response.success) {

          NotificationManager.success(response.message, "Success");
          window.location.href = "/products";
        } else {
          NotificationManager.error(response.message, "Error");
        }
      }
      else {
        console.log(response) 
        if(response.productId) {
        window.location.href = "/product/"+response.productId;
        }
      }
      } else {
        NotificationManager.error(
          "Missing Mandatory fields",
          "Validation Error"
        );
      }
    } catch (err) {
      NotificationManager.error("Something went wrong", "Error");
    }
  }
  validateProduct(product) {
    if (product.name && product.code && product.uom && product.listPrice) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    return (
      <div id="db-page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid create-product">
            <div className="row">
              <div className="col-lg-12">
                <Titlebox title="Create Product" />
              </div>
            </div>

            <div className="row">
              <div className={  this.state.product && this.state.product._id ? "col-lg-8 col-md-8 col-sm-12 col-xs-12" : "col-lg-12 col-md-12 col-sm-12 col-xs-12"}>
                <BasicInfo
                  product={this.state.product}
                  updateDetails={this.updateDetails}
                  categories={this.state.categories}
                  selectedCategories={this.state.product.categories}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                {this.state.product && this.state.product._id &&
                <ImageManagement product={this.state.product}
                  updateDetails={this.updateDetails} />}
              </div>
            </div>
            {this.state.product && this.state.product._id &&
            <div className="row">

              <AdvancedInfo product={this.state.product} attributes={this.state.attributes}
                updateDetails={this.updateDetails} 
                submitAction={this.submitAction}/>
            </div> }
            <div className="row">
              <div className="col-lg-12 text-right">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  title="Close"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8C1.33333 11.6819 4.3181 14.6667 8 14.6667ZM8 13.3333C5.05448 13.3333 2.66667 10.9455 2.66667 8C2.66667 5.05448 5.05448 2.66667 8 2.66667C10.9455 2.66667 13.3333 5.05448 13.3333 8C13.3333 10.9455 10.9455 13.3333 8 13.3333ZM5.5286 8.4714L7.5286 10.4714C7.78895 10.7318 8.21106 10.7318 8.4714 10.4714C8.73175 10.2111 8.73175 9.78894 8.4714 9.5286L7.60948 8.66667H10C10.3682 8.66667 10.6667 8.36819 10.6667 8C10.6667 7.63181 10.3682 7.33333 10 7.33333H7.60948L8.4714 6.4714C8.73175 6.21105 8.73175 5.78895 8.4714 5.5286C8.21106 5.26825 7.78895 5.26825 7.5286 5.5286L5.5286 7.5286C5.40357 7.65362 5.33333 7.82319 5.33333 8C5.33333 8.17681 5.40357 8.34638 5.5286 8.4714Z"
                      fill=""
                    />
                  </svg>
                  Back{" "}
                </button>
                <button
                  type="button"
                  className="btn btn-primary ml-4"
                  title="Save"
                  onClick={(e) => this.submitAction(this.state.product && this.state.product._id ? "save" : "next")}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 14.6667L4 14.6667C2.89543 14.6667 2 13.7712 2 12.6667V3.33333C2 2.22876 2.89543 1.33333 4 1.33333H8H10.4837C11.0842 1.33333 11.6529 1.60315 12.0328 2.06826L13.5491 3.92495C13.8407 4.28205 14 4.72895 14 5.19001V8V12.6667C14 13.7712 13.1046 14.6667 12 14.6667ZM12 13.3333C12.3682 13.3333 12.6667 13.0349 12.6667 12.6667L12.6667 8V5.19001C12.6667 5.03633 12.6136 4.88736 12.5164 4.76832L11.0001 2.91164C10.913 2.80506 10.7962 2.72924 10.6667 2.69226V4.66667C10.6667 5.03486 10.3682 5.33333 10 5.33333H5.33333C4.96514 5.33333 4.66667 5.03486 4.66667 4.66667V2.66667H4C3.63181 2.66667 3.33333 2.96514 3.33333 3.33333L3.33333 12.6667C3.33333 13.0349 3.63181 13.3333 4 13.3333L12 13.3333ZM6 2.66667L8 2.66667L9.33333 2.66667V4H6V2.66667ZM11.3333 8.66667C11.3333 8.29848 11.0349 8 10.6667 8L5.33333 8C4.96514 8 4.66667 8.29848 4.66667 8.66667L4.66667 11.3333C4.66667 11.7015 4.96514 12 5.33333 12H10.6667C11.0349 12 11.3333 11.7015 11.3333 11.3333V8.66667ZM10 9.33333V10.6667H6L6 9.33333L10 9.33333Z"
                      fill="white"
                    ></path>
                  </svg>
                  {this.state.product && this.state.product._id ? "Save" : "Next"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    selectedProduct: state.products.selectedProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductfromAPI: (productId) => dispatch(getProductfromAPI(productId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddProduct));