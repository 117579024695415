import * as React from "react";
import { withRouter } from "react-router-dom"
import { connect } from "react-redux";
import { selectProduct } from "../../redux/products/products.actions";

class ProductGrid extends React.Component {

  render() {
    const { history } = this.props;
    return (
      <div className="row">
        {this.props.products &&
          this.props.products.map((product, index) => {
            return (
              <div className="col-lg-3 col-md-3 col-sm-6" key={index} onClick={(e)=>history.push("product/"+product._id)}>
                <figure className="card card-product min-height mb-4">
                  <div className="product-badge">
                    {" "}
                    {product.newProduct && (
                      <span
                        className="badge badge-md badge-cosmic-latte"
                        title="New"
                      >
                        {" "}
                        New{" "}
                      </span>
                    )}{" "}
                    {product.saleProduct && (
                      <span
                        className="badge badge-md badge-cosmic-latte"
                        title="Sale"
                      >
                        {" "}
                        Sale{" "}
                      </span>
                    )}
                    {product.hideProduct && (
                      <span
                        className="badge badge-md badge-cosmic-latte"
                        title="Hidden"
                      >
                        {" "}
                        Hidden{" "}
                      </span>
                    )}
                  </div>
                  <div className="img-wrap">
                    {" "}
                    <img
                      src={
                        product.images && product.images.length>0 ? product.images[0] :
                        "/images/products/no-image-available.jpg"
                      }
                      alt="Product Img"
                    />{" "}
                  </div>
                  <figcaption className="info-wrap text-left p-3">
                    <div className="title text-truncate mb-2">
                      <div className="product-name">{product.name}</div>
                    </div>
                    <div className="product-code mb-2">{product.code}</div>
                    <div className="price-wrap">
                      {" "}
                      <span className="price-new">
                        <span className="price-symbol">₹</span>
                        {product.listPrice}
                      </span>
                      <span className="set ml-2">Each</span>{" "}
                      {product.saleProduct && (
                        <del className="price-old ml-2">
                          <span className="price-symbol">₹</span>
                          {product.salePrice}
                        </del>
                      )}{" "}
                      <span className="pure-checkbox pull-right">
                        <input
                          id={"checkbox" + product._id}
                          name="checkbox"
                          type="checkbox"
                          onChange={(e) =>
                            this.props.selectProduct(
                              product._id,
                              e.target.checked
                            )
                          }
                        />
                        <label
                          htmlFor={"checkbox" + product._id}
                          className="m-0"
                        ></label>
                      </span>
                    </div>
                  </figcaption>
                </figure>
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedProducts: state.selectedProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectProduct: (data, checked) => dispatch(selectProduct(data, checked)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductGrid));
