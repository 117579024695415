import { LIST_CATEGORIES, DELETE_CATEGORY } from "./categories.types";
import { fetchCategories } from "../../api/categories";
import { beginApiCall, apiCallError } from "../apiStatus/api.actions";

export const deleteProduct = (data, checked) => {
  console.log(data);
  return {
    type: DELETE_CATEGORY,
    payload: { data, checked },
  };
};

export const listCategoriesSuccess = (categories) => {
  return {
    type: LIST_CATEGORIES,
    payload: categories,
  };
};

export const listCategories = (bodyObj) => {
  return async (dispatch) => {
    dispatch(beginApiCall());
    try {
      let categories = await fetchCategories(bodyObj);
      dispatch(listCategoriesSuccess(categories));
    } catch (err) {}
  };
};
