import axios from "axios";
import CONFIG from "../config";

export const fetchProducts = async () => {
  try {
    let response = await axios.post(
      CONFIG.BASEURL + "/admin/products/listAllProducts",
      {}
    );
    return response.data.products;
  } catch (err) { }
};

export const deleteProducts = async (productIds) => {
  try {
    let response = await axios.post(
      CONFIG.BASEURL + "/admin/products/deleteProducts",
      { productIds: productIds }
    );
    return response.data;
  } catch (err) { }
};

export const getProduct = async (id) => {
  try {
    let response = await axios.get(
      CONFIG.BASEURL + "/admin/products/" + id
    );
    return response.data;
  } catch (err) { }
}

export const createProduct = async (product) => {
  try {
    let response = await axios.post(
      CONFIG.BASEURL + "/admin/products/add", product
    );
    return response.data;
  } catch (err) { }
}

export const updateProduct = async (product) => {
  try {
    let response = await axios.post(
      CONFIG.BASEURL + "/admin/products/update", product
    );
    return response.data;
  } catch (err) { }
}