import axios from "axios";
import CONFIG from "../config";

export const fetchOrders = async () => {
    try {
        let response = await axios.post(
            CONFIG.BASEURL + "/admin/orders/listAllOrders",
            {}
        );
        if (response.data.success === true) {
            return response.data.orders;
        }
        else {
            throw response.data;
        }
    } catch (err) {
        throw err;
    }
}


export const getOrder = async (id) => {
    try {
        let response = await axios.get(
            CONFIG.BASEURL + "/admin/orders/"+id,
            {}
        );
        if (response.data.success === true) {
            return response.data.order;
        }
        else {
            throw response.data;
        }
    } catch (err) {
        throw err;
    }
}