import * as React from "react";

export default class CategoriesList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="product-list-view table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th className="text-center">
                    Select
                    <i
                      className="ml-2 mdi mdi-alert-circle-outline"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Click to select the Category"
                    ></i>
                  </th>
                  <th>
                    Catalog name
                    <i
                      className="ml-2 mdi mdi-alert-circle-outline"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="admin.catalog.catalog.name.title"
                    ></i>
                  </th>
                  <th>
                    Type
                    <i
                      className="ml-2 mdi mdi-alert-circle-outline"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="admin.catalog.type.title"
                    ></i>
                  </th>
                  <th className="text-center">
                    Add Category
                    <i
                      className="ml-2 mdi mdi-alert-circle-outline"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="admin.catalog.add.a  Category"
                    ></i>
                  </th>
                  <th className="text-center">
                    Add Content
                    <i
                      className="ml-2 mdi mdi-alert-circle-outline"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="admin.catalog.add.a Content"
                    ></i>
                  </th>
                  <th className="text-center">
                    Edit
                    <i
                      className="ml-2 mdi mdi-alert-circle-outline"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="admin.catalog.add.a Content"
                    ></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.categories &&
                  this.props.categories.map((category, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="pure-checkbox text-center">
                            <input
                              id={"checkbox" + category._id}
                              name="checkbox"
                              type="checkbox"
                            />
                            <label
                              htmlFor={"checkbox" + category._id}
                              className="m-0"
                            ></label>
                          </div>
                        </td>
                        <td>
                          {" "}
                          <a
                            onClick={(e) =>
                              !category.leaf &&
                              (window.location.href =
                                "/categories/" + category._id)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {category.name}
                          </a>
                        </td>
                        <td>{category.type}</td>
                        <td>
                          <div className="action text-center">
                            {" "}
                            <a
                              title="Add Category"
                              onClick={(e) => {
                                this.props.handleShow(category._id);
                              }}
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M3.75 16.5L14.25 16.5C15.4926 16.5 16.5 15.4926 16.5 14.25L16.5 3.75C16.5 2.50736 15.4926 1.5 14.25 1.5H3.75C2.50736 1.5 1.5 2.50736 1.5 3.75V14.25C1.5 15.4926 2.50736 16.5 3.75 16.5ZM15 14.25C15 14.6642 14.6642 15 14.25 15L3.75 15C3.33579 15 3 14.6642 3 14.25L3 3.75C3 3.33579 3.33579 3 3.75 3L14.25 3C14.6642 3 15 3.33578 15 3.75L15 14.25ZM12 10.5C12.8284 10.5 13.5 9.82843 13.5 9V6C13.5 5.17157 12.8284 4.5 12 4.5L6 4.5C5.17157 4.5 4.5 5.17157 4.5 6L4.5 9C4.5 9.82843 5.17157 10.5 6 10.5L12 10.5ZM12 9H10.5L7.5 9H6V7.5L6 6H7.5L10.5 6H12V7.5V9ZM4.5 12.75C4.5 12.3358 4.83579 12 5.25 12H9C9.41421 12 9.75 12.3358 9.75 12.75C9.75 13.1642 9.41421 13.5 9 13.5H5.25C4.83579 13.5 4.5 13.1642 4.5 12.75ZM11.25 12C10.8358 12 10.5 12.3358 10.5 12.75C10.5 13.1642 10.8358 13.5 11.25 13.5H12C12.4142 13.5 12.75 13.1642 12.75 12.75C12.75 12.3358 12.4142 12 12 12H11.25Z"
                                  fill="#0A6CFF"
                                />
                              </svg>
                            </a>{" "}
                          </div>
                        </td>
                        <td>
                          <div className="action text-center">
                            {" "}
                            <a
                              title="Add Content"
                              data-toggle="modal"
                              data-target="#add-content"
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M4.5 16.5L13.5 16.5C14.7426 16.5 15.75 15.4926 15.75 14.25V9V6L15.75 3.74999C15.75 2.50736 14.7426 1.5 13.5 1.5H11.625H8.99999H4.5C3.25736 1.5 2.25 2.50736 2.25 3.75V14.25C2.25 15.4926 3.25736 16.5 4.5 16.5ZM14.25 14.25C14.25 14.6642 13.9142 15 13.5 15L4.5 15C4.08578 15 3.75 14.6642 3.75 14.25L3.75 3.75C3.75 3.33579 4.08578 3 4.5 3L9 3L11.625 3L13.5 3C13.9142 3 14.25 3.33578 14.25 3.75L14.25 6V9V14.25ZM6.75 4.5C5.92157 4.5 5.25 5.17157 5.25 6V6.75C5.25 7.57843 5.92157 8.25 6.75 8.25H11.25C12.0784 8.25 12.75 7.57843 12.75 6.75V6C12.75 5.17157 12.0784 4.5 11.25 4.5H6.75ZM6.75 6H11.25V6.75H6.75V6ZM5.25 10.5C5.25 10.0858 5.58579 9.75 6 9.75H12C12.4142 9.75 12.75 10.0858 12.75 10.5C12.75 10.9142 12.4142 11.25 12 11.25H6C5.58579 11.25 5.25 10.9142 5.25 10.5ZM6 12C5.58579 12 5.25 12.3358 5.25 12.75C5.25 13.1642 5.58579 13.5 6 13.5H12C12.4142 13.5 12.75 13.1642 12.75 12.75C12.75 12.3358 12.4142 12 12 12H6Z"
                                  fill="#0A6CFF"
                                />
                              </svg>
                            </a>{" "}
                          </div>
                        </td>
                        <td>
                          <a
                            onClick={(e) => {
                              this.props.handleupdateShow(category);
                            }}
                          >
                            Edit
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="modal fade" id="add-category" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="modal-head">
                      <button type="button" className="close" data-dismiss="modal">
                        <i className="mdi mdi-close"></i>
                      </button>
                      <h4>Add category</h4>
                    </div>
                    <div className="modal-copy">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="input">
                              Name<span className="mandatory">*</span>
                            </label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="input">
                              Start date<span className="mandatory">*</span>
                            </label>
                            <div className="date-input-icon"></div>
                            <input
                              type="text"
                              className="form-control date"
                              id="datepicker"
                              placeholder="dd/mm/yyyy"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="input">Meta tag title</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="input">Meta tag description</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="input">Meta tag keyboard</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="input">
                              Category type<span className="mandatory">*</span>
                            </label>
                            <select className="select-db form-control">
                              <option value="">Custom Select</option>
                              <option value="">Custom Select</option>
                              <option value="">Custom Select</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="pure-checkbox form-group">
                            <input id="dipc" name="checkbox" type="checkbox" />
                            <label for="dipc">
                              Display in printable Catalog
                              <i
                                className="ml-2 mdi mdi-alert-circle-outline"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="admin.catalog.check.needs.to.display.printableCatalog"
                              ></i>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="pure-checkbox">
                            <input id="dim" name="checkbox" type="checkbox" />
                            <label for="dim">
                              Display in menu
                              <i
                                className="ml-2 mdi mdi-alert-circle-outline"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Check the box if the category/page needs to be displayed in header menu"
                              ></i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 text-right">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          title="Close"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.8047 3.13807C14.0651 2.87772 14.0651 2.45561 13.8047 2.19526C13.5444 1.93491 13.1223 1.93491 12.8619 2.19526L8 7.05719L3.13807 2.19526C2.87772 1.93491 2.45561 1.93491 2.19526 2.19526C1.93491 2.45561 1.93491 2.87772 2.19526 3.13807L7.05719 8L2.19526 12.8619C1.93491 13.1223 1.93491 13.5444 2.19526 13.8047C2.45561 14.0651 2.87772 14.0651 3.13807 13.8047L8 8.94281L12.8619 13.8047C13.1223 14.0651 13.5444 14.0651 13.8047 13.8047C14.0651 13.5444 14.0651 13.1223 13.8047 12.8619L8.94281 8L13.8047 3.13807Z"
                              fill=""
                            ></path>
                          </svg>
                          Close{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary ml-4"
                          title="Save Changes"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 14.6667L4 14.6667C2.89543 14.6667 2 13.7712 2 12.6667V3.33333C2 2.22876 2.89543 1.33333 4 1.33333H8H10.4837C11.0842 1.33333 11.6529 1.60315 12.0328 2.06826L13.5491 3.92495C13.8407 4.28205 14 4.72895 14 5.19001V8V12.6667C14 13.7712 13.1046 14.6667 12 14.6667ZM12 13.3333C12.3682 13.3333 12.6667 13.0349 12.6667 12.6667L12.6667 8V5.19001C12.6667 5.03633 12.6136 4.88736 12.5164 4.76832L11.0001 2.91164C10.913 2.80506 10.7962 2.72924 10.6667 2.69226V4.66667C10.6667 5.03486 10.3682 5.33333 10 5.33333H5.33333C4.96514 5.33333 4.66667 5.03486 4.66667 4.66667V2.66667H4C3.63181 2.66667 3.33333 2.96514 3.33333 3.33333L3.33333 12.6667C3.33333 13.0349 3.63181 13.3333 4 13.3333L12 13.3333ZM6 2.66667L8 2.66667L9.33333 2.66667V4H6V2.66667ZM11.3333 8.66667C11.3333 8.29848 11.0349 8 10.6667 8L5.33333 8C4.96514 8 4.66667 8.29848 4.66667 8.66667L4.66667 11.3333C4.66667 11.7015 4.96514 12 5.33333 12H10.6667C11.0349 12 11.3333 11.7015 11.3333 11.3333V8.66667ZM10 9.33333V10.6667H6L6 9.33333L10 9.33333Z"
                              fill="white"
                            ></path>
                          </svg>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="add-content" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="modal-head">
                      <button type="button" className="close" data-dismiss="modal">
                        <i className="mdi mdi-close"></i>
                      </button>
                      <h4>Add content</h4>
                    </div>
                    <div className="modal-copy">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="input">
                              Name<span className="mandatory">*</span>
                            </label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="input">
                              Start date<span className="mandatory">*</span>
                            </label>
                            <div className="date-input-icon"></div>
                            <input
                              type="text"
                              className="form-control date"
                              id="datepicker"
                              placeholder="dd/mm/yyyy"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="input">Meta tag title</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="input">Meta tag description</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="input">Meta tag keyboard</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label for="input">
                              Category type<span className="mandatory">*</span>
                            </label>
                            <select className="select-db form-control">
                              <option value="">Custom Select</option>
                              <option value="">Custom Select</option>
                              <option value="">Custom Select</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="pure-checkbox form-group">
                            <input id="dipcd" name="checkbox" type="checkbox" />
                            <label for="dipcd">
                              Display in printable Catalog
                              <i
                                className="ml-2 mdi mdi-alert-circle-outline"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="admin.catalog.check.needs.to.display.printableCatalog"
                              ></i>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="pure-checkbox">
                            <input id="dimd" name="checkbox" type="checkbox" />
                            <label for="dimd">
                              Display in menu
                              <i
                                className="ml-2 mdi mdi-alert-circle-outline"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Check the box if the category/page needs to be displayed in header menu"
                              ></i>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 text-right">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          title="Close"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.8047 3.13807C14.0651 2.87772 14.0651 2.45561 13.8047 2.19526C13.5444 1.93491 13.1223 1.93491 12.8619 2.19526L8 7.05719L3.13807 2.19526C2.87772 1.93491 2.45561 1.93491 2.19526 2.19526C1.93491 2.45561 1.93491 2.87772 2.19526 3.13807L7.05719 8L2.19526 12.8619C1.93491 13.1223 1.93491 13.5444 2.19526 13.8047C2.45561 14.0651 2.87772 14.0651 3.13807 13.8047L8 8.94281L12.8619 13.8047C13.1223 14.0651 13.5444 14.0651 13.8047 13.8047C14.0651 13.5444 14.0651 13.1223 13.8047 12.8619L8.94281 8L13.8047 3.13807Z"
                              fill=""
                            ></path>
                          </svg>
                          Close{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary ml-4"
                          title="Save Changes"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 14.6667L4 14.6667C2.89543 14.6667 2 13.7712 2 12.6667V3.33333C2 2.22876 2.89543 1.33333 4 1.33333H8H10.4837C11.0842 1.33333 11.6529 1.60315 12.0328 2.06826L13.5491 3.92495C13.8407 4.28205 14 4.72895 14 5.19001V8V12.6667C14 13.7712 13.1046 14.6667 12 14.6667ZM12 13.3333C12.3682 13.3333 12.6667 13.0349 12.6667 12.6667L12.6667 8V5.19001C12.6667 5.03633 12.6136 4.88736 12.5164 4.76832L11.0001 2.91164C10.913 2.80506 10.7962 2.72924 10.6667 2.69226V4.66667C10.6667 5.03486 10.3682 5.33333 10 5.33333H5.33333C4.96514 5.33333 4.66667 5.03486 4.66667 4.66667V2.66667H4C3.63181 2.66667 3.33333 2.96514 3.33333 3.33333L3.33333 12.6667C3.33333 13.0349 3.63181 13.3333 4 13.3333L12 13.3333ZM6 2.66667L8 2.66667L9.33333 2.66667V4H6V2.66667ZM11.3333 8.66667C11.3333 8.29848 11.0349 8 10.6667 8L5.33333 8C4.96514 8 4.66667 8.29848 4.66667 8.66667L4.66667 11.3333C4.66667 11.7015 4.96514 12 5.33333 12H10.6667C11.0349 12 11.3333 11.7015 11.3333 11.3333V8.66667ZM10 9.33333V10.6667H6L6 9.33333L10 9.33333Z"
                              fill="white"
                            ></path>
                          </svg>
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
