import * as React from "react";

export default class Pagination extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      itemCounts : []
    }
  }

  componentDidMount(){
    const { itemCount } = this.props
    let array = Array.from({length: itemCount / 10}, (_, index) => index + 1);
    this.setState({
      itemCounts : array
    })
  }

  render() {
    const { itemCounts } = this.state
    return       itemCounts.length > 0 &&  (
      <div className="row">
        <div className="col-lg-12">
          <div className="pull-right">
            <nav aria-label="Page navigation">
              <ul className="pagination">
                <li>
                  {" "}
                  <a href="#" aria-label="Previous">
                    {" "}
                    <span aria-hidden="true">
                      <i className="mdi mdi-chevron-left"></i>
                    </span>{" "}
                  </a>{" "}
                </li>
                {
                  itemCounts.map(value=>(
                    <li>
                    <a href="#">{value}</a>
                  </li>
                  ))
                }
                <li>
                  {" "}
                  <a href="#" aria-label="Next">
                    {" "}
                    <span aria-hidden="true">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>{" "}
                  </a>{" "}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>)
  }
}
