import * as React from "react";
import Titlebox from "../common/Titlebox";
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { getAllAdminUsers,saveUser,deleteAdminUser } from '../../api/adminusers'
import UserList from "./UserList";

class AdminUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            deleteModalIsOpen: false,
            newUser: {},
            adminUsers: [],
            selectedIds:[],
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    componentDidUpdate() {

    }

    async createUser() {
        const { newUser } = this.state;
        let user = await saveUser(newUser);
        let index = this.state.adminUsers.findIndex(us=>us._id==user._id);
        if(index > -1) {
            const tempArray = [...this.state.adminUsers];
            tempArray[index] = user;
            this.setState({adminUsers:tempArray,  newUser: {}})
        }
        else {
            this.setState({adminUsers:[...this.state.adminUsers,user],  newUser: {}})

        }

        this.closeModal();
      }


    async getUsers() {
        try {
            let users = await getAllAdminUsers();
            this.setState({ adminUsers: users })
        }
        catch (err) {

        }
    }

    openModal(user) {
        if (user) {
            this.setState({ modalIsOpen: true, newUser: { firstname: user.firstname, lastname: user.lastname, email: user.email, password: "", confirmPassword: "", _id: user._id } });
        }
        else {
            this.setState({ modalIsOpen: true, newAttribute: { firstname: "", lastname: "", email: "", password: "", confirmPassword: "" } });
        }
    }
    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    select(id,selection){
        let ids = this.state.selectedIds;
        if(selection==true){
          ids.push(id)
        }
        else{
          ids.splice(ids.indexOf(id),1)
        }
        this.setState({selectedIds : ids})
      }

      openDeleteModal(){
        this.setState({ deleteModalIsOpen: true});
      }

      closeDeleteModal(){
        this.setState({ deleteModalIsOpen: false});
      }

     async removeUser(){
        try {
            let users =  await deleteAdminUser({selectedIds:this.state.selectedIds});
            this.setState({ adminUsers: users })
            this.closeDeleteModal()
        }
        catch(err) {
            console.log(err)
        }
 
        
    }

    render() {
        const { modalIsOpen, deleteModalIsOpen } = this.state
        return (
            <div id="db-page-wrapper">
                <div className="page-content-tab">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <Titlebox title="Admin Users" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <div className="pull-left">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                title="Add Attribute"
                                                onClick={(e) => this.openModal()}
                                            >
                                                Add User
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary ml-0 ml-md-4 ml-lg-4"
                                                title="Delete"
                                                onClick={e => this.openDeleteModal()}
                                            >
                                                <span
                                                    className="hidden-xs"
                                                //   onClick={(e) => {
                                                //     this.props.deleteProducts(this.props.selectedProducts)
                                                //   }}
                                                >
                                                    Delete
                                                </span>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        {this.state.adminUsers.length > 0 && (
                                            <UserList users={this.state.adminUsers} openModal={user => this.openModal(user)} select={(id, selection) => this.select(id, selection)} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer id="footer" className="text-left">
                        &copy; <script>document.write(new Date().getFullYear())</script>{" "}
                        eComchain Inc. All rights reserved.
                    </footer>
                </div>
                <Modal
                    show={modalIsOpen}
                    animation={false}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Add User
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="Fist Name" value={this.state.newUser && this.state.newUser.firstname} onChange={e => this.setState({ newUser: { ...this.state.newUser, firstname: e.target.value } })} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Last Name" value={this.state.newUser && this.state.newUser.lastname} onChange={e => this.setState({ newUser: { ...this.state.newUser, lastname: e.target.value } })} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" placeholder="Email" value={this.state.newUser && this.state.newUser.email} onChange={e => this.setState({ newUser: { ...this.state.newUser, email: e.target.value } })} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" value={this.state.newUser && this.state.newUser.password} onChange={e => this.setState({ newUser: { ...this.state.newUser, password: e.target.value } })} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" placeholder="Confirm Password" value={this.state.newUser && this.state.newUser.confirmPassword} onChange={e => this.setState({ newUser: { ...this.state.newUser, confirmPassword: e.target.value } })} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={e => this.createUser()} disabled={this.state.newUser.password=='' || this.state.newUser.password != this.state.newUser.confirmPassword}>Save</Button>
                        <Button onClick={e => this.closeModal()}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={deleteModalIsOpen}
                    animation={false}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Delete
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Do you want to delete the selected attribute(s)?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={e => this.removeUser()}>Delete</Button>
                        <Button onClick={e => this.closeDeleteModal()}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default AdminUsers;
