import * as React from "react";
import AttributeList from "./attributes-list-view";
import Titlebox from "../common/Titlebox";
import Pagination from "../common/Pagination";
import { connect } from "react-redux";
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { listAttributes, addAttribute,deleteAttribute } from "../../redux/attributes/attributes.action";
import { NotificationManager } from "react-notifications";

class Attributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      deleteModalIsOpen:false,
      selectedIds:[],
      attributeName: "",
      attributeValueName: "",
      attributeValueValue: ""
    };
  }

  componentDidMount() {
    this.props.listAttributes();
  }

  componentDidUpdate() {
    if (this.props.apiError != '') {
      NotificationManager.error(this.props.apiError, "Error");
    }
    if (this.props.apiSuccess != '') {
      NotificationManager.success(this.props.apiSuccess, "Success");
    }
  }

  openDeleteModal(){
    this.setState({ deleteModalIsOpen: true});
  }
  openModal(attribute) {
    if (attribute) {
      this.setState({ modalIsOpen: true, newAttribute:{ name: attribute.name, values: attribute.values,_id:attribute._id }  });
    }
    else {
      this.setState({ modalIsOpen: true, newAttribute: { name: "", values: [] } });
    }
  }

  addNewAttributeValue() {
    const { newAttribute, attributeValueName, attributeValueValue } = this.state;
    this.setState({ newAttribute: { ...newAttribute, values: [...newAttribute.values, { attr_name: attributeValueName, attr_val: attributeValueValue }] } })
    this.setState({ attributeValueName: '', attributeValueValue: '' })
  }

  saveAttribute() {
    const { newAttribute } = this.state;
    this.props.addAttribute(newAttribute);
    this.closeModal();
  }

  deleteValues(value) {
    const { newAttribute } = this.state;
    const index = newAttribute.values.indexOf(value)
    if(index>-1){
      if(newAttribute.values[index]._id){
      newAttribute.values[index]["deleted"] = true;
      }
      else{
        newAttribute.values.splice(index,1)
      }
      console.log(newAttribute)
      this.setState({newAttribute:newAttribute})
    }
  }

  closeDeleteModal(){
    this.setState({ deleteModalIsOpen: false});
  }

  removeAttribute(){
      this.props.deleteAttribute({selectedIds:this.state.selectedIds});
      this.closeDeleteModal()
  }
  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  select(id,selection){
    let ids = this.state.selectedIds;
    if(selection==true){
      ids.push(id)
    }
    else{
      ids.splice(ids.indexOf(id),1)
    }
    this.setState({selectedIds : ids})
  }

  render() {
    const { modalIsOpen,deleteModalIsOpen } = this.state
    return (
      <div id="db-page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <Titlebox title="Attributes" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-rounded">
                  <div className="card-body">
                    <div className="pull-left">
                      <button
                        type="button"
                        className="btn btn-primary"
                        title="Add Attribute"
                        onClick={(e) => this.openModal()}
                      >
                        Add Attribute
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary ml-0 ml-md-4 ml-lg-4"
                        title="Delete"
                        onClick={e=>this.openDeleteModal()}
                      >
                        <span
                          className="hidden-xs"
                        //   onClick={(e) => {
                        //     this.props.deleteProducts(this.props.selectedProducts)
                        //   }}
                        >
                          Delete
                        </span>
                      </button>

                    </div>
                  </div>
                  <div className="modal fade" id="delete" role="dialog">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body">
                          <div className="modal-head">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              <i className="mdi mdi-close"></i>
                            </button>
                            <h4>Delete</h4>
                          </div>
                          <div className="modal-copy">
                            <div className="row">
                              <div className="col-lg-12">
                                <p>
                                  Do you want to delete the selected attribute(s)?
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 text-right">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                title="Close"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.8047 3.13807C14.0651 2.87772 14.0651 2.45561 13.8047 2.19526C13.5444 1.93491 13.1223 1.93491 12.8619 2.19526L8 7.05719L3.13807 2.19526C2.87772 1.93491 2.45561 1.93491 2.19526 2.19526C1.93491 2.45561 1.93491 2.87772 2.19526 3.13807L7.05719 8L2.19526 12.8619C1.93491 13.1223 1.93491 13.5444 2.19526 13.8047C2.45561 14.0651 2.87772 14.0651 3.13807 13.8047L8 8.94281L12.8619 13.8047C13.1223 14.0651 13.5444 14.0651 13.8047 13.8047C14.0651 13.5444 14.0651 13.1223 13.8047 12.8619L8.94281 8L13.8047 3.13807Z"
                                    fill=""
                                  ></path>
                                </svg>
                                No{" "}
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary ml-4"
                                title="Select"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.774 4.21342C14.0704 4.50282 14.076 4.97766 13.7866 5.274L6.46237 12.774C6.31479 12.9251 6.11032 13.007 5.89922 12.9995C5.68812 12.992 5.48997 12.8959 5.35346 12.7347L2.17768 8.98469C1.90999 8.6686 1.94923 8.19535 2.26532 7.92766C2.58141 7.65997 3.05466 7.69921 3.32235 8.0153L5.96528 11.1361L12.7134 4.226C13.0028 3.92965 13.4777 3.92402 13.774 4.21342Z"
                                    fill="white"
                                  ></path>
                                </svg>
                                Yes{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-rounded">
                  <div className="card-body">
                    {this.props.attributes.length > 0 && (
                      <AttributeList attributes={this.props.attributes} openModal={attr => this.openModal(attr)} select={(id,selection)=>this.select(id,selection)} />
                    )}
                    <Pagination />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer id="footer" className="text-left">
            &copy; <script>document.write(new Date().getFullYear())</script>{" "}
            eComchain Inc. All rights reserved.
          </footer>
        </div>
        <Modal
          show={modalIsOpen}
          animation={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Attributes
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Attribute Name</Form.Label>
                <Form.Control type="text" placeholder="Attribute Name" value={this.state.newAttribute && this.state.newAttribute.name} onChange={e => this.setState({ newAttribute: { ...this.state.newAttribute, name: e.target.value } })} />
              </Form.Group>
              <b>Attribute Values</b>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Values</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.newAttribute && this.state.newAttribute.values && this.state.newAttribute.values.map((value, index) => {
                      return (
                        !value.deleted &&<tr>
                        <td>{index + 1}</td>
                        <td>{value.attr_name}</td>
                        <td>{value.attr_val}</td>
                        <td><a onClick={e=>this.deleteValues(value)}>Delete</a></td>
                      </tr>
                      
                        
                      )
                    })
                  }
                </tbody>
              </Table>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label> Name</Form.Label>
                <Form.Control type="text" placeholder="Name" value={this.state.attributeValueName} onChange={e => this.setState({ attributeValueName: e.target.value })} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label> Value</Form.Label>
                <Form.Control type="text" placeholder="Value" value={this.state.attributeValueValue} onChange={e => this.setState({ attributeValueValue: e.target.value })} />
              </Form.Group>
              <Button onClick={e => this.addNewAttributeValue()}> Add Value</Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={e => this.saveAttribute()}>Save</Button>
            <Button onClick={e => this.closeModal()}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={deleteModalIsOpen}
          animation={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Delete
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p>
                                  Do you want to delete the selected attribute(s)?
                                </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={e => this.removeAttribute()}>Delete</Button>
            <Button onClick={e => this.closeDeleteModal()}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    attributes: state.attributes.attributes,
    apiError: state.api.apiError,
    apiSuccess: state.api.apiSuccess
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listAttributes: () => dispatch(listAttributes()),
    addAttribute: (bodyObj) => dispatch(addAttribute(bodyObj)),
    deleteAttribute:(bodyObj)=>dispatch(deleteAttribute(bodyObj))

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Attributes);
