import { combineReducers } from "redux";
import productReducer from "./products/products.reducer";
import categoryReducer from "./categories/categories.reducer";
import attributeReducer from "./attributes/attributes.reducer";
import apiReducer from "./apiStatus/api.reducer";
import userReducer from "./users/users.reducer";

const rootReducer = combineReducers({
  products: productReducer,
  categories: categoryReducer,
  attributes: attributeReducer,
  api: apiReducer,
  users: userReducer
});

export default rootReducer;
