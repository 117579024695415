import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  NotificationContainer
} from "react-notifications";
import Sidebar from "./common/Sidebar";
import "./App.css";
import Login from "./login/login";
import Topbar from "./common/Topbar";
import Products from "./products/products";
import Categories from "./categories/categories";
import AddProduct from "./products/add-product";
import Attributes from "./attributes/attributes";
import Dashboard from "./dashboard/dashboard";
import AdminUsers from "./users/Adminusers";
import TrackOrder from "./trackOrder/trackOrder";
import OrderDetails from "./trackOrder/OrderDetails";

const WithContainer = () => (
  <div>
      <Route path="/dashboard" exact>
      <Dashboard />
    </Route>
    <Route path="/products" exact>
      <Products />
    </Route>
    <Route path="/add-product" exact>
      <AddProduct />
    </Route>
    <Route path="/product/:id" exact>
      <AddProduct />
    </Route>
    <Route path="/categories" exact>
      <Categories />
    </Route>
    <Route path="/categories/:id" exact>
      <Categories />
    </Route>
    <Route path="/attributes" exact>
      <Attributes />
    </Route>
    <Route path="/adminusers" exact>
      <AdminUsers />
    </Route>
    <Route path="/trackorder" exact>
      <TrackOrder />
    </Route>
    <Route path="/orderDetails/:id" exact>
      <OrderDetails />
    </Route>
    <Sidebar />
    <Topbar />
  </div>)

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Login />
          </Route>
          <Route component={WithContainer} />
        </Switch>
      </BrowserRouter>
      <NotificationContainer />
    </>
  );
}

export default App;
