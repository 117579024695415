import { SELECT_PRODUCT, LIST_PRODUCTS, GET_PRODUCT } from "./products.types";
import { getProduct,fetchProducts,deleteProducts } from "../../api/products";
import { beginApiCall, apiCallError } from "../apiStatus/api.actions";

export const selectProduct = (data, checked) => {
  console.log(data);
  return {
    type: SELECT_PRODUCT,
    payload: { data, checked },
  };
};

export const listProductsSuccess = (products) => {
  return {
    type: LIST_PRODUCTS,
    payload: products,
  };
};

export const getProductSuccess = (product) => {
  return {
    type: GET_PRODUCT,
    payload: product,
  };
};

export const listProducts = () => {
  return async (dispatch) => {
    dispatch(beginApiCall());
    try {
      let products = await fetchProducts();
      dispatch(listProductsSuccess(products));
    } catch (err) {}
  };
};

export const deleteProduct = (productIds) => {
  return async (dispatch) => {
    dispatch(beginApiCall());
    try {
      let result = await deleteProducts(productIds);
      console.log(result)
      if(result && result.success == true){
        let products = await fetchProducts();
        dispatch(listProductsSuccess(products));
      }
    } catch (err) {}
  };
};

export const getProductfromAPI = (id) => {
  console.log("test")
  return async (dispatch) => {
    console.log("test2")
    dispatch(beginApiCall());
    try {
      console.log("test1")
      let result = await getProduct(id);
      if(result.success==true){
        let product = result.product
        console.log(result)
        dispatch(getProductSuccess(product));
      }
 
    } catch (err) {
      console.log(err)
    }
  };
};