import { SAVE_USER } from "./users.types";

const INITIAL_STATE = {
    loggedInUser: {}
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SAVE_USER:
        return { ...state, loggedInUser: {...action.payload} };
        default:
            return state;
    }
}

export default userReducer;