// require("dotenv").config();

// const { REACT_APP_ENVIRONMENT } = process.env;
let CONFIG = {};
const REACT_APP_ENVIRONMENT = "stage"
if (REACT_APP_ENVIRONMENT === "local") {
  CONFIG = {
    BASEURL: "http://localhost:3200",
  };
} else if (REACT_APP_ENVIRONMENT === "stage") {
  CONFIG = {
    BASEURL: "https://chelle-api.onrender.com",
  };
}

export default CONFIG;
