import * as React from "react";

export default class Topbar extends React.Component {
  render() {
    return (
      <div className="page-title-box">
        <h1 className="page-title">
          {/* <span className="page-title-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M15 8C16.1046 8 17 7.10457 17 6C17.5523 6 18 6.44772 18 7V19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V7C6 6.44772 6.44772 6 7 6C7 7.10457 7.89543 8 9 8H15ZM7.17071 4C7.58254 2.83481 8.69378 2 10 2H14C15.3062 2 16.4175 2.83481 16.8293 4H17C18.6569 4 20 5.34315 20 7V19C20 20.6569 18.6569 22 17 22H7C5.34314 22 4 20.6569 4 19V7C4 5.34315 5.34314 4 7 4H7.17071ZM9 6H10H11H13H14H15V5C15 4.44772 14.5523 4 14 4H10C9.44772 4 9 4.44772 9 5V6ZM8 12C8 11.4477 8.44772 11 9 11H15C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13H9C8.44772 13 8 12.5523 8 12ZM9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15H9Z" fill="white"/>
              </svg>
              </span> */}
          {this.props.title}
        </h1>
      </div>
    );
  }
}
